import { React, useEffect, useState } from "react";

const Followers = (props) => {
  if (props.origin == "profiles") {
    return (
      <div className="row d-flex align-items-center mx-0 mb-4 bg-dark py-3 px-2 rounded">
        <div className="col-2 ps-0">
          <img
            style={{ height: "43px", aspectRatio: 1, objectFit: "cover" }}
            className="rounded-circle"
            src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
          />
        </div>
        <div className="col-8 text-start ps-2">
          <h2 className="fs-4  mb-0 pb-1">{props.follower}</h2>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row d-flex align-items-start mx-0">
        <div className="col-2 ps-0">
          <img
            style={{ height: "43px", aspectRatio: 1, objectFit: "cover" }}
            className="rounded-circle"
            src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
          />
        </div>
        <div className="col-8 text-start ps-2">
          <h2 className="fs-4  mb-0 pb-1">John Doe</h2>
          <p className="mt-0 pt-0 text-secondary">Loved It!</p>
        </div>
        <div className="col-2 text-start">
          <h1 className="display-6 "> 8.8</h1>
        </div>
      </div>
    );
  }
};

export default Followers;
