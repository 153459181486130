import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const SignUp = () => {
  document.title = "Sign Up";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [udata, setUdata] = useState({
    username: "",
    fname: "",
    lname: "",
    email: "",
    password: "",
    password2: "",
    spotify_id: localStorage.getItem("spotid"),
  });

  // SpotifyLogin ==>
  const CLIENT_ID = "7a443e934bc94931aba38889dc03532d";
  const REDIRECT_URI = "https://critiq.se/signup";
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
  const RESPONSE_TYPE = "token";

  const [token, setToken] = useState("");

  useEffect(() => {
    const hash = window.location.hash;
    let token = localStorage.getItem("token");

    if (!token && hash) {
      token = hash
        .substring(1)
        .split("&")
        .find((elem) => elem.startsWith("access_token"))
        .split("=")[1];
      console.log("*****" + token);
      window.location.hash = "";
      localStorage.setItem("token", token);

      Axios.get("https://api.spotify.com/v1/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        
      }).then((response) => {
        localStorage.setItem("spotid", response.data.id);
        setUdata({ ...udata, spotify_id: response.data.id });
      });
    }

    setToken(token);
  }, []);

  const logout = () => {
    setToken("");
    window.localStorage.removeItem("token");
  };

  // SpotifyLogin End===>

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/auth/validate-user/", {}, config)
      .then((response) => console.log("ok"))
      .catch(() => {
        // removeCookie("uToken");
      });
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    setIsloading(true);

    console.log(udata);
    Axios.post("https://api.critiq.se/auth/user-register", JSON.stringify(udata))
      .then((response) => {
        setResponse(response.data);
        setUdata({
          username: "",
          fname: "",
          lname: "",
          email: "",
          password: "",
          password2: "",
          spotify_id: "",
        });
        toast.success("Success");
        setIsloading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setResponse(error.message);
        toast.error("Something went Wrong");
        setIsloading(false);
        console.log(error.data);
      });
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}

      <div className="card  bg-none shadow-lg rounded" style={{ border: "none", height: "100vh", overflow: "auto", background: "transparent" }}>
        <div className="d-flex h-100 align-items-center justify-content-center ">
          {!token ? (
            <a className="btn btn-lg btn-light rounded-pill" href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}>
              Login to Spotify <i className="fa fa-spotify"></i>
            </a>
          ) : (
            <form id="uform" onSubmit={handlesubmit} className="row col-11  p-4 my-5 text-light h-100  " data-aos="fade-down">
              <h3 className="fw-bold">Sign Up</h3>
              {/* {response !== "" && <p className="fw-bold text-info">{response}</p>} */}
              <hr />

              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="text"
                    className="form-control  text-light shadow "
                    id="fname"
                    placeholder="John"
                    autoComplete="off"
                    value={udata.fname}
                    onChange={(e) => setUdata({ ...udata, fname: e.target.value })}
                  />
                  <label for="fname" className="">
                    First Name
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="text"
                    className="form-control text-light shadow "
                    id="lname"
                    placeholder="Jacobs"
                    autoComplete="off"
                    value={udata.lname}
                    onChange={(e) => setUdata({ ...udata, lname: e.target.value })}
                  />
                  <label for="lname" className="">
                    Last Name
                  </label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="email"
                    className="form-control text-light shadow "
                    id="Email"
                    autoComplete="new-email"
                    placeholder="John@email.com"
                    value={udata.email}
                    onChange={(e) => setUdata({ ...udata, email: e.target.value })}
                  />
                  <label for="Email" className="form-a">
                    Email
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="password"
                    className="form-control  text-light shadow "
                    id="Password"
                    autoComplete="new-password"
                    placeholder="Minimum 8 characters"
                    value={udata.password}
                    onChange={(e) => setUdata({ ...udata, password: e.target.value })}
                  />
                  <label for="Password" className="">
                    Password
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="password"
                    className="form-control text-light shadow "
                    id="Password"
                    autoComplete="new-password"
                    placeholder="Minimum 8 characters"
                    value={udata.password2}
                    onChange={(e) => setUdata({ ...udata, password2: e.target.value })}
                  />
                  <label for="Password" className="">
                    Confirm - Password
                  </label>
                </div>
              </div>

              {udata.password !== udata.password2 && <span className="d-block px-2  py-1 my-2 rounded bg-warning text-dark text-lead">Password and Confirm - Password should Match</span>}

              <div className="col-12">
                <div className="form-floating">
                  <input
                    style={{ background: "transparent" }}
                    type="text"
                    className="form-control  text-light shadow "
                    id="UserName"
                    placeholder="John Jacobs"
                    autoComplete="off"
                    value={udata.username}
                    onChange={(e) => setUdata({ ...udata, username: e.target.value })}
                  />
                  <label for="UserName" className="">
                    UserName
                  </label>
                </div>
              </div>

              <div className="col-12 py-2">
                <button
                  type="submit"
                  className="btn w-100 fw-bold py-2 btn-lg text-light"
                  style={{
                    background: "radial-gradient(circle, rgba(254,97,97,1) 0%, rgba(255,48,48,1) 100%)",
                  }}
                >
                  Continue
                </button>
              </div>
              <p className="lead">
                Already have an Account?
                <Link to="/login" state={{ check: "Login" }} className="link-info text-decoration-none fw-bold">
                  {" "}
                  Login
                </Link>
              </p>
            </form>
          )}
        </div>
        <Outlet />
      </div>
    </>
  );
};
export default SignUp;
