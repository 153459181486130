import React from "react";

const Header = () => {
  return (
    <nav class="navbar navbar-light bg-black sticky-top py-3">
      <div class="container">
        <a class="navbar-brand fw-bold text-light"></a>
        <div className="ms-auto fs-3 text-light d-flex justify-content-end align-items-center ">
          <i className="fa fa-paper-plane me-5"></i>
          <i className="fa fa-bell"></i>
        </div>
      </div>
    </nav>
  );
};

export default Header;
