import { React, useEffect, useState } from "react";

const Comments = (props) => {
  return (
    <div className="row d-flex align-items-center mx-0 py-3 px-2 mb-3 rounded-4" style={{ backgroundColor: props.origin == "comments" ? "rgb(49,49,49)" : "rgb(5, 5, 5)" }}>
      {/* {console.log(props)} */}
      <div className="col-2 ps-0">
        <img
          style={{ height: "43px", aspectRatio: 1, objectFit: "cover" }}
          className="rounded-circle"
          src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
        />
      </div>
      <div className="col-8 text-start ps-2">
        <h2 className="fs-4  mb-0 pb-1">{props.username}</h2>
        <p className="my-0 py-0 text-secondary">{props.comment}</p>
      </div>
      <div className="col-2 text-start">
        <h1 className="display-6 "> {props.ratings}</h1>
      </div>
    </div>
  );
};

export default Comments;
