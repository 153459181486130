import React from "react";
import { useState, useEffect } from "react";

import SignUp from "./signup";
import Login from "./login";

import { useCookies } from "react-cookie";
import { useLocation, useParams, Link, Outlet } from "react-router-dom";

import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "./user.css";
const User = () => {
  const params = useParams();

  const location = useLocation();
  console.log(location.pathname);
  const [rimages, setRimages] = useState([{}]);
  const [userUpdata, setUserupdata] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isverified, setVerified] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [tokenres, setTokenres] = useState("");

  const [elog, setElog] = useState(false);
  console.log(params.red);
  // if (isverified) {
  //   window.location.href = `/main`;
  // }

  return (
    <div className="authBg">
      {!isverified && location.pathname == "/" && (
        <div className="text-light d-flex align-items-center justify-content-center p-3" style={{ height: "100vh" }}>
          <div className="hero h-100 d-flex align-items-center">
            <div className="herotext">
              <h3 className="fw-bold text-center mb-5">CRITIQ</h3>
              <div className="bod mb-5">
                <h3 className="fw-bold">Music Made Social</h3>

                <p className="fw-light pt-1 text-lead">Let your music network do the job for you. Follow your freinds and find new music</p>
              </div>
              <div className="bottoms mt-auto d-flex justify-content-between w-100">
                <Link to="/login" className="btn btn-outline-light w-100 me-5 rounded-pill py-2">
                  {" "}
                  Login
                </Link>
                <Link to="/signup" className="btn btn-light w-100 rounded-pill py-2">
                  {" "}
                  Signup
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isverified && location.pathname == "/login" && <Login />}
      {!isverified && location.pathname == "/signup" && <SignUp />}
      <Outlet />
    </div>
  );
};
export default User;
