import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";
const Userupdate = (props) => {
  document.title = "User-Userupdate";

  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [response, setResponse] = useState([]);
  const [tresponse, setTresponse] = useState("");
  const [uemail, setUemail] = useState(props.oldprofile.email);
  const [udata, setUdata] = useState({
    username: localStorage.getItem("cUser"),
    email: props.oldprofile.email,
    image:
      props.oldprofile.profile_picture !== ""
        ? "https://api.critiq.se/media/" + props.oldprofile.profile_picture
        : "",
    name: props.oldprofile.name,
  });
  const [isLoading, setIsloading] = useState(false);

  // Image Compression
  function compressAndConvertToBase64(image, callback) {
    var canvas = document.createElement("canvas");
    var maxWidth = 800;
    var maxHeight = 800;

    var width = image.width;
    var height = image.height;

    if (width > maxWidth || height > maxHeight) {
      if (width > height) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      } else {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    canvas.toBlob(
      function (blob) {
        var reader = new FileReader();
        reader.onloadend = function () {
          var compressedBase64 = reader.result;
          callback(compressedBase64);
        };
        reader.readAsDataURL(blob);
      },
      "image/jpeg",
      0.8
    );
  }
  // Image Compression

  const handlesubmit = (e) => {
    setIsloading(true);

    e.preventDefault();
    // window.location.href = "/main";
    // const FD = new FormData(document.getElementById("uform"));
    // let formData = new FormData(); //formdata object

    // FD.append("username", udata.username);
    // FD.append("upw", udata.email); //append the values with key, value pair
    // // FD.append('age', 20);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    console.log(config);
    console.log(udata);

    setTresponse("");
    Axios.post("https://api.critiq.se/auth/update-profile/", JSON.stringify(udata), config)
      .then((response) => {
        localStorage.setItem("cUser", udata.username);
        toast.success("Success");
        setIsloading(false);
        props.recall(props.ogchk + 1);
      })
      .catch((error) => {
        console.log(error);
        setResponse(error.message);
        toast.error("Something went Wrong");
        setIsloading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}
      {console.log(uemail)}
      <Toaster />
      <div
        className="card"
        style={{ background: "transparent", border: "none", height: "100vh", overflowY: "scroll" }}
      >
        <div className="d-flex align-items-start justify-content-center mb-5">
          <form
            id="uform"
            onSubmit={handlesubmit}
            className="row g-3 col-11 col-md-5 p-4 pb-5 text-light rounded shadow-lg bg-none h-100 mb-5"
            // style={{ background: "#36393f" }}
            data-aos="fade-down"
          >
            <h3 className="fw-bold">Update Profile</h3>
            {tresponse.length !== 0 && <p className="fw-bold text-info">{tresponse}</p>}
            <hr />
            <div className="col-12 my-3">
              <label for="EpImg" style={{ position: "relative" }}>
                <img
                  src={udata.image !== "" ? udata.image : "/defAvatar.png"}
                  height="150"
                  width="150"
                  className="rounded-circle"
                  style={{ objectFit: "cover", filter: udata.image !== "" ? "" : "contrast(0.9)" }}
                />
                <i
                  className="fa fa-edit fs-4"
                  style={{ position: "absolute", top: "10%", right: "11%" }}
                ></i>
              </label>
              <input
                type="file"
                hidden="true"
                onChange={(e) => {
                  if (e.target.files[0] !== undefined) {
                    let reader = new FileReader();
                    reader.addEventListener("load", () => {
                      let image = new Image();
                      image.onload = () => {
                        compressAndConvertToBase64(image, (compressedBase64) => {
                          setUdata({
                            ...udata,
                            image: compressedBase64,
                          });
                        });
                      };
                      image.src = reader.result;
                    });
                    reader.readAsDataURL(e.target.files[0]);
                  } else {
                    setUdata({ ...udata, image: "" });
                  }
                }}
                className="shadow rounded"
                id="EpImg"
                placeholder="Add Gallery Image"
              />
            </div>
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control bg-none text-light"
                  style={{ background: "transparent" }}
                  id="Username"
                  autoComplete="user-name"
                  placeholder="Dave124"
                  value={udata.username}
                  onChange={(e) => setUdata({ ...udata, username: e.target.value })}
                />
                <label for="Username" className="text-light">
                  Username
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control bg-none text-light"
                  style={{ background: "transparent" }}
                  id="Full Name"
                  autoComplete="Full Name"
                  placeholder="Dave Jonas"
                  value={udata.name}
                  onChange={(e) => setUdata({ ...udata, name: e.target.value })}
                />
                <label for="Full Name" className="text-light">
                  Full Name
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="Email"
                  className="form-control bg-none  text-light "
                  id="Email"
                  style={{ background: "transparent" }}
                  autoComplete="new-Email"
                  placeholder="Minimum 8 characters"
                  value={udata.email}
                  onChange={(e) => setUdata({ ...udata, email: e.target.value })}
                />
                <label for="Email" className="text-light">
                  Email
                </label>
              </div>
            </div>

            <div className="col-12 py-2">
              <button
                type="submit"
                className="btn w-100 fw-bold py-2 btn-lg text-light"
                style={{
                  background:
                    "radial-gradient(circle, rgba(254,97,97,1) 0%, rgba(255,48,48,1) 100%)",
                }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Userupdate;
