import React, { useState } from "react";
import FeedCard from "./feedcards";

const Tcard = (props) => {
  const getMonthName = (monthNumber) => new Date(0, monthNumber - 1).toLocaleString("default", { month: "long" });
  const [cMonth, setCmonth] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  if (props.origin == "toptracks") {
    return (
      <>
        <div
          data-bs-toggle="offcanvas"
          data-bs-target={`#offcanvasBottom` + props.count}
          aria-controls={`offcanvasBottom` + props.count}
          className="card bg-dark text-white me-2"
          onClick={() => {
            setCmonth(props.thismonth);
            props.monthset(props.thismonth);
            console.log(props.thismonth);
          }}
          style={{ width: "230px", display: "inline-block", cursor: "pointer", borderRadius: "5px 15px " }}
        >
          <img
            src={`${"https://ourculturemag.com/wp-content/uploads/2022/01/PUP-UNRAVELING.jpeg"}`}
            className="card-img opacity-75"
            style={{ height: "100%", objectFit: "cover", height: "100%", maxHeight: "150px", borderRadius: "5px 15px " }}
            alt="..."
            loading="lazy"
          />
          {/* <img
            src={`${props.tracksData.track_data[0].images[0] ? props.tracksData.track_data[0].images[0].url : "https://ourculturemag.com/wp-content/uploads/2022/01/PUP-UNRAVELING.jpeg"}`}
            className="card-img opacity-75"
            style={{ height: "100%", objectFit: "cover", height: "100%", maxHeight: "150px", borderRadius: "5px 15px " }}
            alt="..."
            loading="lazy"
          /> */}
          <div className="card-img-overlay">
            <h5 className="card-title fw-bold">Tracks</h5>
            <h5 className="">{getMonthName(props.thismonth)}</h5>
          </div>
        </div>

        <div class="offcanvas offcanvas-bottom h-100 bg-black border-0" tabindex="-1" id={`offcanvasBottom` + props.count} aria-labelledby={`offcanvasBottomLabel` + props.count}>
          <div class="offcanvas-header d-block">
            <button type="button" className="btn btn-lg bg-none text-light ps-0" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => props.monthset(-1)}>
              <i className="fa fa-chevron-down"></i>
            </button>
            <div className="d-flex justify-content-between align-items-center">
              <h5 class="offcanvas-title text-light    display-5 my-0" id="offcanvasBottomLabel">
                Tracks {getMonthName(cMonth)}
              </h5>
              <i className="fa fa-bar-chart fs-3 text-light fw-bold"></i>
            </div>
          </div>
          <div class="offcanvas-body small" style={{ paddingBottom: "200px" }}>
            {props.tracksData !== undefined && props.tracksData.length !== 0 && (
              <>
                {props.tracksData.map((ini, i) => {
                  return <FeedCard id={i} origin="tophome" trackRecord={ini} props={props.props} />;
                })}

                {props.tracksData.length >= 5 && (
                  <div className="w-100 d-flex justify-content-center pt-4">
                    {props.loadingTT && (
                      <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {!props.loadingTT && !props.isFull && (
                      <button
                        className="btn btn-light rounded-pill d-block btn-sm"
                        onClick={() => (props.currntLoad == props.tracksData.length ? props.loadMore(props.currntLoad + 5) : console.log("tlength:" + props.tracksData.length))}
                      >
                        Load More
                      </button>
                    )}
                    {props.isFull && <h6 className="text-light opacity-75"> All Caught Up! </h6>}
                  </div>
                )}
              </>
            )}

            {props.loadingTT && props.tracksData.length == 0 && (
              <div style={{ height: "100%", width: "100%" }} className="d-flex align-items-center mt-5">
                <div class="loader2 mx-auto"></div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else if (props.origin == "activity") {
    return (
      <div
        className="me-3"
        style={{ width: props.copened == "activity" ? "45%" : "150px", display: "inline-block", cursor: "pointer" }}
        // onClick={() => {
        //   props.props.setGTrackpre(props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
        //   props.props.setGTrackId(props.trackRecord.id);
        //   props.props.setGTrackName(props.trackRecord.name);
        //   props.props.setGArtistName(props.trackRecord.artists.name);
        //   props.props.setGAlbumId(props.trackRecord.id);
        //   props.props.setGArtistId(props.trackRecord.artists.id);
        //   props.props.setGExtern(props.trackRecord.external_urls.spotify);
        //   props.props.setGTrackImg(props.trackRecord.images.url);

        //   // MINI player song data
        //   localStorage.setItem("trackPreview", props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
        //   localStorage.setItem("trackImg", props.trackRecord.images.url);

        //   // MINI player song data
        // }}
      >
        <div className="card bg-dark text-white ">
          <img src={props.image} className="card-img" style={{ height: "100%", objectFit: "cover", height: "100%", maxHeight: "150px", opacity: "60%" }} alt="..." loading="lazy" />
          <div className="card-img-overlay text-wrap" style={{ top: "unset" }}>
            <h6 className="card-title fw-bold mb-0" style={{ fontSize: "17px" }}>
              {props.tracksData.name}
            </h6>
            <h6 style={{ fontSize: "15px" }} className="mb-0">
              Polyphia
            </h6>
          </div>
        </div>
        <div className="text-center text-light mt-2">
          <h6 style={{ fontSize: "13px" }}>{props.time}</h6>{" "}
        </div>
      </div>
    );
  } else if (props.origin == "popuser") {
    return (
      <div className="me-3" style={{ width: "120px", display: "inline-block", cursor: "pointer" }}>
        <div className="card bg-black text-white ">
          <img
            src={"https://i.pinimg.com/736x/fb/30/60/fb30604896721f16fb13d1cbdfc1bc55.jpg"}
            className="card-img"
            style={{ height: "100%", objectFit: "cover", height: "100%", maxHeight: "150px", opacity: "60%" }}
            alt="..."
            loading="lazy"
          />
          <div className="card-img-overlay text-wrap w-100" style={{ top: "unset" }}>
            <h6 className="card-title fw-bold mb-0" style={{ fontSize: "15px" }}>
              Emma Jacobs
            </h6>
            <h6 style={{ fontSize: "13px" }} className="mb-0">
              @emmaj123
            </h6>
          </div>
        </div>
        <div className="mt-2 text-center">
          {isLoading ? (
            <button disabled="true" className="btn btn-secondary rounded-pill px-3 ">
              Processing <i className="fa fa-cog fa-spin fs-5 align-middle "></i>
            </button>
          ) : (
            <>
              {!isFollowing ? (
                <button className="btn btn-sm btn-light rounded-pill px-3 pe-1 ">
                  Follow <i className="fa fa-plus-circle fs-5 align-middle ps-2"></i>
                </button>
              ) : (
                <button className="btn btn-sm btn-outline-danger rounded-pill px-3 ">
                  Unfollow <i className="fa fa-minus-circle fs-5 align-middle ps-2"></i>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
};

export default Tcard;
