import { React, useState, useEffect } from "react";
import SearchWindow from "./search";
import Axios from "axios";

const Spotify = (props) => {
  const CLIENT_ID = "7a443e934bc94931aba38889dc03532d";
  const REDIRECT_URI = "https://critiq.se/search";
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
  const RESPONSE_TYPE = "token";
  const [token, setToken] = useState("");
  useEffect(() => {
    const hash = window.location.hash;
    let token = localStorage.getItem("token");

    if (!token && hash) {
      token = hash
        .substring(1)
        .split("&")
        .find((elem) => elem.startsWith("access_token"))
        .split("=")[1];
      console.log("*****" + token);
      window.location.hash = "";
      localStorage.setItem("token", token);

      Axios.get("https://api.spotify.com/v1/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => console.log(response.data))
        .catch(() => {
          setToken(false);
          localStorage.removeItem("token");
        });
    }

    setToken(token);
  }, []);

  const logout = () => {
    setToken("");
    window.localStorage.removeItem("token");
  };

  return (
    <div className="bg-black" style={{ height: "100vh" }}>
      {!token ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <a
            className="btn btn-lg btn-light rounded-pill my-auto mx-auto"
            href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}
          >
            Login to Spotify <i className="fa fa-spotify"></i>
          </a>
        </div>
      ) : (
        <SearchWindow props={props} />
      )}
    </div>
  );
};

export default Spotify;
