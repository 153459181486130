import React from "react";
import { Link, Outlet } from "react-router-dom";
const BtmNav = (props) => {
  return (
    <nav class="navbar fixed-bottom navbar-expand-sm navbar-dark bg-black shadow-lg" style={{ zIndex: "9999" }}>
      <div class="container">
        <div className="d-flex align-items-center justify-content-between w-100 px-3 py-3">
          <Link to="/main">
            <i className="fa fa-home display-5 text-light  opacity-25"></i>
          </Link>
          <Link to="/search">
            <i className="fa fa-search display-5 text-light  opacity-25"></i>
          </Link>
          <Link to="user">
            <img style={{ height: "50px", aspectRatio: 1, objectFit: "cover" }} className="rounded-circle" src={props.dp ? props.dp : localStorage.getItem("userProfile")} />
          </Link>
        </div>
      </div>
      <Outlet />
    </nav>
  );
};

export default BtmNav;
