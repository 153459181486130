import { React, useEffect } from "react";
import FeedCard from "./feedcards";
import Userupdate from "./forms/updateuser";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";
import { useState } from "react";
import Followers from "./lists/followerlist";

// import "./master.css";
const UserDash = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [isLoading, setIsloading] = useState(false);
  const [udata, setUdata] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [chk, setChk] = useState(0);
  const [followerlist, setFollowerList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [toggleFerList, setFerlist] = useState(false);
  const [toggleFollowingList, setToggleFollowingList] = useState(false);

  const [filter, setFilter] = useState("a");
  const [toptracks, setToptracks] = useState([]);
  const [recentlyAdded, setRecentlyAdded] = useState([]);

  const [loadTracksTop, setLoadTracksTop] = useState(5);
  const [loadTracksRecent, setLoadTracksRecent] = useState(5);
  const [isLoadingTracks, setIsLoadingTracks] = useState(false);
  const [load1, setLoad1] = useState(1);
  const [load2, setLoad2] = useState(1);

  const [isFullRecent, setIsFullRecent] = useState(false);
  const [isFullTr, setIsFullTr] = useState(false);
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/auth/validate-user/", {}, config)
      .then((response) => console.log(response))
      .catch(() => {
        removeCookie("uToken");
        window.location.href = "/";
      });

    const loaders = { start_index: 0, end_index: 5, is_tracks: false };

    Axios.post("https://api.critiq.se/user/get-profile", loaders, config)
      .then((response) => {
        setUdata(response.data);
        console.log("get-profile----------------------");
        console.log(response.data);
        setIsloading(false);
        localStorage.setItem("userProfile", `https://api.critiq.se/media/` + response.data.profile_picture);
        props.setdp(`https://api.critiq.se/media/` + response.data.profile_picture);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [chk]);

  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/user/get-all-followers", {}, config)
      .then((response) => {
        console.log("------***----");
        console.log(response.data);
        setFollowerList(
          response.data
            .filter((e) => e.user == localStorage.getItem("cUser"))
            .map((ini) => {
              return ini.followers;
            })
        );
        setFollowingList(
          response.data
            .filter((e) => e.user == localStorage.getItem("cUser"))
            .map((ini) => {
              return ini.following;
            })
        );
      })
      .catch(() => {
        toast.error("Something Went wrong!");
      });
  }, []);

  //  Recently Added ===>
  useEffect(() => {
    setIsLoadingTracks(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    const loaders = { start_index: loadTracksRecent - 5, end_index: loadTracksRecent };

    console.log("fetching more--");
    console.log(loaders);

    Axios.post("https://api.critiq.se/tracks/recently-added", loaders, config)
      .then((response) => {
        console.log("------***----tracks");
        console.log(response.data);
        console.log("Recent Len: " + response.data.length);
        setRecentlyAdded([...recentlyAdded, ...response.data]);
        if (response.data.length < 5) {
          console.log("Recent Full");
          setIsFullRecent(true);
        }

        setIsLoadingTracks(false);
      })
      .catch(() => {
        toast.error("Something Went wrong!");
        setIsLoadingTracks(false);
      });
  }, [loadTracksRecent]);

  // Toprated and ==>
  useEffect(() => {
    setIsLoadingTracks(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    const loaders2 = { start_index: loadTracksTop - 5, end_index: loadTracksTop };

    console.log("fetching more--");
    console.log(loaders2);

    Axios.post("https://api.critiq.se/tracks/top-rated", loaders2, config)
      .then((response) => {
        setIsLoadingTracks(false);
        console.log("------TOP RATED TRACKS-----");

        console.log([...toptracks, ...response.data]);
        setToptracks([...toptracks, ...response.data]);

        if (response.data.length < 5) {
          console.log("Top Full");
          setIsFullTr(true);
        }
        setTotalReviews(response.data.length);
      })
      .catch((error) => {
        console.log(error);
        // toast.error("didn't load");
        setIsLoadingTracks(false);
      });
  }, [loadTracksTop]);

  return (
    <>
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}
      <Toaster />
      {console.log("454554")}
      {console.log(recentlyAdded)}

      <div className="bg-black" style={{ minHeight: "100vh" }}>
        {console.log(udata)}
        <div
          style={{
            boxShadow: "inset 0 -10 10px rgba(0,0,0,0.9)",
            backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url('${"https://api.critiq.se/media/" + udata.profile_picture}') `,
            minHeight: "100vh",
            height: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
          }}
        >
          <div className="settings text-end pe-4 w-25 ms-auto" style={{ position: "sticky", top: "0%", right: "5%", zIndex: "99" }}>
            <h1 className="display-2 fw-bold text-light w-25 ms-auto" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSettings" aria-controls="offcanvasSettings">
              {" "}
              ...
            </h1>
          </div>
          <div className="container mb-5 pt-3">
            <div className="d-flex align-items-center h-100">
              <div className="bg-dark bg-opacity-50 p-lg-4 p-3 rounded text-center text-light w-100">
                <h1 className="display-4">{udata.name}</h1>
                <p className="text-lead">@{localStorage.getItem("cUser")}</p>
                <div className="d-flex w-100 justify-content-between">
                  <span className="text-center">
                    <h3 className="fw-bold">{totalReviews}</h3>
                    <p className="fs-5 fw-light opacity-75">Review</p>
                  </span>
                  <span className="text-center" style={{ cursor: "pointer" }} onClick={() => setFerlist(true)}>
                    <h3 className="fw-bold">{udata.followers}</h3>
                    <p className="fs-5 fw-light opacity-75">Followers</p>
                  </span>
                  <span className="text-center" style={{ cursor: "pointer" }} onClick={() => setToggleFollowingList(true)}>
                    <h3 className="fw-bold">{udata.following}</h3>
                    <p className="fs-5 fw-light opacity-75">Following</p>
                  </span>
                </div>
                {/* <div className="text-center">
                  <button className="btn btn-light rounded-pill mx-auto" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUpdateForm" aria-controls="offcanvasUpdateForm">
                    Update <i className="ps-3 fa fa-edit"></i>
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div
            className="container "
            style={{
              background: "rgba(0,0,0,0.3)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px 20px 0 0",
            }}
          >
            <div className="text-center w-100 bg-black" style={{ position: "sticky", top: "0", zIndex: "999" }}>
              <div class="dropdown">
                <button
                  class="btn btn-lg dropdown-toggle fw-bold text-light opacity-75"
                  type="button"
                  style={{ background: "transparent" }}
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filter == "a" ? "Top Rated Tracks" : "Recently Added"}
                </button>
                <ul class="dropdown-menu uDashdd" aria-labelledby="dropdownMenuButton1">
                  <li onClick={() => setFilter("a")}>
                    <a class="dropdown-item text-light" href="#">
                      Top Rated Tracks
                    </a>
                  </li>
                  <li onClick={() => setFilter("b")}>
                    <a class="dropdown-item text-light" href="#">
                      Recently Added
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {filter == "a" ? (
              <>
                {toptracks !== undefined && toptracks.length !== 0 && (
                  <div className="hidescroll" style={{ paddingBottom: "200px", paddingTop: "20px", minHeight: "60vh" }}>
                    {/* {console.log(toptracks.map((ini) => ini.name))} */}
                    {toptracks.map((ini, i) => {
                      return <FeedCard id={i} origin="profiles" trackRecord={ini} props={props} />;
                    })}

                    {toptracks.length > 4 && (
                      <div className="w-100 d-flex justify-content-center pt-4">
                        {isLoadingTracks && (
                          <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                            <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        )}
                        {!isLoadingTracks && !isFullTr && (
                          <button className="btn btn-light rounded-pill d-block btn-sm" onClick={() => (toptracks.length == loadTracksTop ? setLoadTracksTop(loadTracksTop + 5) : setLoad2(load2 + 1))}>
                            Load More
                          </button>
                        )}
                        {isFullTr && <h6 className="text-light opacity-75"> All Caught Up! </h6>}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                {recentlyAdded !== undefined && recentlyAdded.length !== 0 && (
                  <div className="hidescroll" style={{ paddingBottom: "200px", paddingTop: "20px", minHeight: "60vh" }}>
                    {/* {console.log(toptracks.map((ini) => ini.name))} */}
                    {recentlyAdded.map((ini, i) => {
                      return <FeedCard id={i} origin="profiles-recent" trackRecord={ini} props={props} />;
                    })}

                    {recentlyAdded.length > 4 && (
                      <div className="w-100 d-flex justify-content-center pt-4">
                        {isLoadingTracks && (
                          <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                            <i className="fa fa-spinner fa-spin"></i>
                          </button>
                        )}
                        {!isLoadingTracks && !isFullRecent && (
                          <button
                            className="btn btn-light rounded-pill d-block btn-sm"
                            onClick={() => (recentlyAdded.length == loadTracksRecent ? setLoadTracksRecent(loadTracksRecent + 5) : setLoad1(load1 + 1))}
                          >
                            Load More
                          </button>
                        )}
                        {isFullRecent && <h6 className="text-light opacity-75"> All Caught Up! </h6>}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-bottom h-100 bg-dark border-0"
        tabindex="-1"
        id="offcanvasUpdateForm"
        aria-labelledby="offcanvasUpdateFormLabel"
        style={{
          height: "80vh",
        }}
      >
        <div class="offcanvas-header d-block">
          <button type="button" className="btn btn-lg bg-none text-light ps-0" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="fa fa-chevron-down"></i>
          </button>
        </div>

        <div class="offcanvas-body-lg">{udata.length !== 0 && <Userupdate recall={(e) => setChk(e)} oldprofile={udata} ogchk={chk} />}</div>
      </div>

      <div class="offcanvas offcanvas-bottom h-75 bg-dark border-0" tabindex="-1" id="offcanvasSettings" aria-labelledby="offcanvasSettingsLabel">
        <div class="offcanvas-header d-block">
          <button type="button" className="btn btn-lg bg-none text-light ps-0" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="fa fa-chevron-down"></i>
          </button>
        </div>

        <div className="offcanvas-body">
          <button
            className="fs-5  mt-0 mb-1 text-light btn bg-none border-0 p-0 d-block"
            role="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasUpdateForm"
            aria-controls="offcanvasUpdateForm"
          >
            Update Profile
          </button>

          <button
            role="button"
            className="fs-5  mt-0 mb-1 text-danger btn bg-none border-0 p-0"
            onClick={() =>
              toast((t) => (
                <span>
                  <b>Log Out? </b>
                  <a
                    className="btn bg-info text-light  mx-2"
                    onClick={() => {
                      removeCookie("uToken");
                      toast.dismiss(t.id);
                      window.location.href = "/";
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </a>
                  <a className="btn bg-dark text-light" onClick={() => toast.dismiss(t.id)}>
                    <i className="fa fa-times"></i>
                  </a>
                </span>
              ))
            }
          >
            Logout <i className="fa fa-sign-out ms-2" />
          </button>
        </div>
      </div>

      {/* Follower List */}
      {toggleFerList && (
        <div
          class=" h-100 bg-black border-0"
          style={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "99",
          }}
          data-aos="fade-up"
        >
          <div class="d-block ps-2">
            <button type="button" className="btn btn-lg bg-none text-light ps-0" onClick={() => setFerlist(false)}>
              <i className="fa fa-chevron-down"></i>
            </button>
          </div>
          {followerlist.length !== 0 && (
            <div className="container p-3 text-light">
              {followerlist[0].map((ini) => {
                return <Followers follower={ini} origin="profiles" />;
              })}
            </div>
          )}
        </div>
      )}
      {/* Follower List END */}
      {/* Following List */}
      {toggleFollowingList && (
        <div
          class=" h-100 bg-black border-0"
          style={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "99",
          }}
          data-aos="fade-up"
        >
          <div class=" d-block ps-2">
            <button type="button" className="btn btn-lg bg-none text-light ps-0" onClick={() => setToggleFollowingList(false)}>
              <i className="fa fa-chevron-down"></i>
            </button>
          </div>
          {followingList.length !== 0 && (
            <div className="container p-3 text-light">
              {followingList[0].map((ini) => {
                return <Followers follower={ini} origin="profiles" />;
              })}
            </div>
          )}
        </div>
      )}
      {/* Following List END */}
    </>
  );
};

export default UserDash;
