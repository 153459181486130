import { React, lazy, useEffect, useState } from "react";
import Header from "./header";
import Tcard from "./topcards";
import FeedCard from "./feedcards";
import BtmNav from "./bottomnav";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { Toaster, toast } from "react-hot-toast";

import "./master.css";
const Main = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [userFeed, setUserFeed] = useState([]);

  const [userRecent, setUserRecent] = useState([]);

  const [isLoading, setIsloading] = useState(false);
  const [isLoadingsm, setIsloadingsm] = useState(false);
  const [isLoadingtt, setIsloadingtt] = useState(false);
  const [isLoadingtt2, setIsloadingtt2] = useState(false);
  const [load, setLoad] = useState(5);
  const [loadAct, setLoadAct] = useState(5);
  const [isOpened, setisOpened] = useState("all");
  // Top rated lists vars==>
  const [loadTracks, setLoadTracks] = useState(5);
  const [openedMonth, setOpenedMonth] = useState(-1);

  const [isFullUserFeed, setIsFullUserFeed] = useState(false);
  const [isTopFull, setIsTopFull] = useState(false);
  const [isFullUserRecent, setUserFullRecent] = useState(false);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/auth/validate-user/", {}, config)
      .then((response) => console.log(response))
      .catch(() => {
        removeCookie("uToken");
        window.location.href = "/";
      });
  }, [cookies.uToken]);

  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    const loaders = { start_index: 0, end_index: 5, is_tracks: false };
    Axios.post("https://api.critiq.se/user/get-profile", loaders, config)
      .then((response) => {
        localStorage.setItem("userProfile", `https://api.critiq.se/media/` + response.data.profile_picture);
        props.setdp(`https://api.critiq.se/media/` + response.data.profile_picture);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        // removeCookie("uToken");
        // localStorage.removeItem("token");
        // window.location.href = "/";
      });
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Calculate the scroll position and window height
  //     const scrollPosition = window.scrollY;
  //     const windowHeight = window.innerHeight;

  //     // Check if the user has reached the end of the screen
  //     if (scrollPosition + windowHeight >= document.body.scrollHeight && isOpened == "feed" && !isLoadingsm && userFeed.length == load) {
  //       console.log("Feed Count" + userFeed.length + " Index Count:" + load);
  //       setLoad(load + 5);
  //     }
  //   };

  //   // Attach the scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isOpened, window.scrollY]);

  // LoadRecent ==>
  useEffect(() => {
    setIsloadingsm(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    console.log("Called Endpoint Recent Activity--");

    Axios.post("https://api.critiq.se/tracks/get-recent-activities", { start_index: loadAct - 5, end_index: loadAct }, config)
      .then((response) => {
        console.log(response.data);

        if (response.data.length < 5 && userRecent.length !== 0) {
          console.log("Feed Full");
          setUserFullRecent(true);
        } else {
          setUserRecent([...userRecent, ...response.data.reverse()]);
        }
        setIsloadingsm(false);
      })
      .catch(() => {
        setUserRecent("no data");
        // toast.error("Something went wrong while fetching feed");
        setIsloadingsm(false);
      });
  }, [loadAct]);

  // Loadfeed ==>
  useEffect(() => {
    setIsloadingsm(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    console.log("Called Endpoint getuser feed--");

    Axios.post("https://api.critiq.se/user/get_users_feed", { start_index: load - 5, end_index: load }, config)
      .then((response) => {
        console.log(response.data);

        setUserFeed([...userFeed, ...response.data.reverse()]);
        if (response.data.length < 5) {
          console.log("Feed Full");
          setIsFullUserFeed(true);
        }
        setIsloadingsm(false);
      })
      .catch(() => {
        setUserFeed("no data");
        // toast.error("Something went wrong while fetching feed");
        setIsloadingsm(false);
      });
  }, [load]);

  // Top rated tracks Months data==>
  const [topMon, setTopMonths] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    const loaders = { start_index: loadTracks - 5, end_index: loadTracks, month: openedMonth };

    if (openedMonth == -1) {
      topMon.length !== 0 && setTopMonths([]);
      setIsloadingtt(true);
      setIsTopFull(false);
      console.log("tracks/get-last-top-months");
      Axios.post("https://api.critiq.se/tracks/get-last-top-months", {}, config)
        .then((response) => {
          console.log("***********");
          console.log(response.data);
          setMonths(response.data.last_months);
          setIsloadingtt(false);
        })
        .catch(() => {
          setMonths("no data");
          setIsloadingtt(false);
        });
    } else {
      setIsloadingtt2(true);
      Axios.post("https://api.critiq.se/tracks/top-rated-by-month", loaders, config)
        .then((response) => {
          console.log("******////*****");
          console.log(response.data);
          setTopMonths([...topMon, ...response.data.reverse()]);
          if (response.data.length < 5) {
            console.log("Top List Full");
            setIsTopFull(true);
          }
          setIsloadingtt2(false);
        })
        .catch(() => {
          setTopMonths("no data");
          // toast.error("Something went wrong while fetching feed");
          setIsloadingtt2(false);
        });
    }
  }, [openedMonth, loadTracks]);

  // Top rated tracks Months data end==>

  // Scroll Top Start
  useEffect(() => {
    if (isOpened !== "all") {
      window.scrollTo(0, 0);
    }
  }, [isOpened]);
  // Scroll Top End

  return (
    <>
      {console.log(userRecent)}
      <Toaster />
      <div className="bg-black" style={{ height: "100%" }}>
        <Header />
        <div className="container">
          {/* Activity cards Start==> */}
          <h3
            className="display-6 mt-1 text-light d-flex align-items-center bg-black"
            onClick={() => setisOpened((ini) => (ini == "activity" ? "all" : "activity"))}
            style={{ position: isOpened == "activity" ? "sticky" : "", top: "0", left: "0", zindex: "99" }}
            data-aos="fade-up"
          >
            Activity
            <i
              className={`fa fa-angle-right display-3 ${isOpened == "activity" ? "ms-4" : "ms-2"}`}
              style={{ transform: isOpened == "activity" ? "rotate(90deg)" : "rotate(0deg) ", transition: "all 0.5s ease-in-out" }}
            ></i>
          </h3>
          <div className="mt-3" style={{ paddingBottom: isOpened == "activity" ? "60px" : "0", minHeight: isOpened == "activity" ? "100%" : "auto" }}>
            {userRecent !== "no data" ? (
              <>
                <div
                  className="mt-3 mb-5 hidescroll"
                  style={{ overflow: isOpened == "activity" ? "auto" : "auto hidden", whiteSpace: isOpened == "activity" ? "normal" : "nowrap" }}
                  data-aos="fade-up"
                >
                  {userRecent.slice(0, userRecent.length).map((ini, i) => {
                    const trackImageJSON = JSON.parse(ini.track_image.replace(/'/g, '"'));
                    const currentTime = new Date();

                    // Parse the provided time in the JSON data
                    const trackTime = new Date(`${ini.date}T${ini.time}Z`);

                    // Calculate the time difference in milliseconds
                    const timeDifference = currentTime - trackTime;

                    // Calculate minutes and hours
                    const minutesDifference = Math.floor(timeDifference / (1000 * 60)) % 60;
                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

                    // Create a single variable with the formatted time difference
                    const formattedTimeDifference = `${hoursDifference}h ${minutesDifference}m`;
                    return <Tcard tracksData={ini} props={props} count={i} origin={"activity"} image={trackImageJSON.url} time={formattedTimeDifference} copened={isOpened} />;
                  })}
                </div>

                {isOpened == "activity" && (
                  <div className="w-100 d-flex justify-content-center">
                    {isLoadingsm && (
                      <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {!isLoadingsm && !isFullUserRecent && (
                      <button className="btn btn-light rounded-pill d-block btn-sm" onClick={() => (loadAct == userRecent.length ? setLoadAct(loadAct + 5) : "")}>
                        Load More
                      </button>
                    )}
                    {isFullUserRecent && <h6 className="text-light opacity-75"> All Caught Up! </h6>}
                  </div>
                )}
              </>
            ) : (
              <div style={{ height: "auto", width: "100%" }} className="d-flex align-items-start py-4 ">
                <h4 className="h4  mt-2 text-light text-center opacity-50">Nothing to show here</h4>
              </div>
            )}
          </div>
          {/* Activity cards End==> */}

          {/* Feed Begins==> */}

          <h3
            className="display-6 mt-1 text-light d-flex align-items-center bg-black"
            onClick={() => setisOpened((ini) => (ini == "feed" ? "all" : "feed"))}
            style={{ position: isOpened == "feed" ? "sticky" : "", top: "0", left: "0", zindex: "99" }}
            data-aos="fade-up"
          >
            Feed
            <i
              className={`fa fa-angle-right display-3 ${isOpened == "feed" ? "ms-4" : "ms-2"}`}
              style={{ transform: isOpened == "feed" ? "rotate(90deg)" : "rotate(0deg) ", transition: "all 0.5s ease-in-out" }}
            ></i>
          </h3>
          <div className="mt-3" style={{ paddingBottom: isOpened == "feed" ? "300px" : "0", minHeight: isOpened == "feed" ? "100vh" : "auto" }}>
            {isLoadingsm && isOpened == "all" && (
              <div style={{ height: isOpened == "feed" ? "100vh" : "100%", width: "100%" }} className="d-flex align-items-center">
                <div class="loader2 mx-auto"></div>
              </div>
            )}

            {userFeed !== "no data" ? (
              <>
                <div className=" hidescroll h-100 bg-black" style={{ overflow: isOpened == "feed" ? "auto" : "auto hidden", whiteSpace: "nowrap" }} data-aos="fade-up">
                  {userFeed.slice(0, userFeed.length).map((ini) => {
                    return <FeedCard id={ini.id} res={ini} origin="home" props={props} opened={isOpened == "feed" ? true : false} />;
                  })}

                  {/* {[...Array(10)].slice(0, load).map((ini, i) => {
                        return <FeedCard id={i} res={ini} origin="home" props={props} opened={isOpened == "feed" ? true : false} />;
                      })} */}
                </div>

                {isOpened == "feed" && (
                  <div className="w-100 d-flex justify-content-center">
                    {isLoadingsm && (
                      <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {!isLoadingsm && !isFullUserFeed && (
                      <button className="btn btn-light rounded-pill d-block btn-sm" onClick={() => (load == userFeed.length ? setLoad(load + 5) : "")}>
                        Load More
                      </button>
                    )}
                    {isFullUserFeed && <h6 className="text-light opacity-75"> All Caught Up! </h6>}
                  </div>
                )}
                {/* {isOpened == "feed" && (
                  <div className="w-100 d-flex justify-content-center">
                    {isLoadingsm ? (
                      <button className="btn btn-light rounded-pill d-block btn-sm" disabled>
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <button
                        className="btn btn-light rounded-pill d-block btn-sm"
                        onClick={() => {
                          setLoad(load + 5);
                        }}
                      >
                        Load More
                      </button>
                    )}
                  </div>
                )} */}
              </>
            ) : (
              <div style={{ height: "50vh", width: "100%" }} className="d-flex align-items-start pt-5">
                <h4 className="h4  mt-5 text-light text-center opacity-50">Nothing to show here, Try Following more users</h4>
              </div>
            )}
          </div>
          {/* Feed Ends==> */}

          {/* Top Rated Songs cards Start==> */}
          {isOpened == "all" && (
            <div>
              <h3 className="display-6 mt-5 text-light d-flex align-items-center">
                Toplists <i className="fa fa-angle-right display-3 ms-2"></i>
              </h3>
              <div className="mt-3 mb-3 hidescroll" style={{ overflow: "auto", whiteSpace: "nowrap", paddingBottom: "30px" }}>
                {isLoadingtt && (
                  <div style={{ height: "100%", width: "100%" }} className="d-flex align-items-center mt-5">
                    <div class="loader2 mx-auto"></div>
                  </div>
                )}

                {!isLoadingtt && months !== undefined && (
                  <>
                    {months.map((ini, i) => {
                      return (
                        <Tcard
                          thismonth={ini}
                          tracksData={topMon}
                          props={props}
                          count={i}
                          origin={"toptracks"}
                          loadMore={(e) => {
                            setLoadTracks(e);
                          }}
                          monthset={(e) => setOpenedMonth(e)}
                          currntLoad={loadTracks}
                          loadingTT={isLoadingtt2}
                          isFull={isTopFull}
                        />
                      );
                    })}

                    {/* {[...Array(7)].map((ini, i) => {
                  return <Tcard tracksData={ini} props={props} count={i} origin={"activity"} />;
                })} */}
                  </>
                )}
              </div>
            </div>
          )}
          {/* Top Rated Songs cards End==> */}

          {/* Popular User cards Start==> */}
          {isOpened == "all" && (
            <div style={{ paddingBottom: "120px" }}>
              <h3 className="display-6 mt-1 text-light d-flex align-items-center">
                Popular Users <i className="fa fa-angle-right display-3 ms-2"></i>
              </h3>
              <div className="mt-3 mb-5 hidescroll" style={{ overflow: "auto", whiteSpace: "nowrap", paddingBottom: "200px" }}>
                {isLoading && (
                  <div style={{ height: "100%", width: "100%" }} className="d-flex align-items-center mt-5">
                    <div class="loader2 mx-auto"></div>
                  </div>
                )}

                {!isLoading && (
                  <>
                    {[...Array(7)].map((ini, i) => {
                      return <Tcard tracksData={ini} props={props} count={i} origin={"popuser"} />;
                    })}
                  </>
                )}
              </div>
            </div>
          )}
          {/* Popular User cards End==> */}
        </div>
      </div>
    </>
  );
};

export default Main;
