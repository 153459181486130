import React from "react";
import FeedCard from "./feedcards";
const Playcard = (props) => {
  return (
    <>
      <div className="card bg-dark text-white me-4 w-100" style={{ cursor: "pointer" }}>
        <img
          src={props.img}
          className="card-img"
          style={{
            height: "100%",
            objectFit: "cover",
            height: "100%",
            maxHeight: "200px",
            opacity: "0.5",
          }}
          alt="..."
        />
        <div className="card-img-overlay">
          <h5 className="card-title fw-bold mb-0">{props.pname}</h5>
          <h5 className="fw-bold mt-0">{props.uname}</h5>
        </div>
      </div>
    </>
  );
};

export default Playcard;
