import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";
const Login = () => {
  document.title = "User-Login";

  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [response, setResponse] = useState([]);
  const [tresponse, setTresponse] = useState("");
  const [udata, setUdata] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/auth/validate-user/", {}, config)
      .then((response) => (window.location.href = "/main"))
      .catch(() => {
        removeCookie("uToken");
      });
  }, []);

  const handlesubmit = (e) => {
    setIsloading(true);

    e.preventDefault();
    // window.location.href = "/main";
    // const FD = new FormData(document.getElementById("uform"));
    // let formData = new FormData(); //formdata object

    // FD.append("username", udata.username);
    // FD.append("upw", udata.password); //append the values with key, value pair
    // // FD.append('age', 20);
    const config = {
      headers: { "content-type": "application/json" },
    };

    setTresponse("");
    Axios.post("https://api.critiq.se/auth/user-login", JSON.stringify(udata))
      .then((response) => {
        typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);
        if (response.data.access) {
          response.length !== 0 && setCookie("uToken", response.data.access, { path: "/" });
          localStorage.setItem("cUser", udata.username);
          setUdata({ username: "", password: "" });
          toast.success("Success");
          setIsloading(false);
          console.log(response.data);
          window.location.href = "/main";
        } else if (response.data.message) {
          toast.error("Invalid Credentials");
          setIsloading(false);
        } else {
          toast.error("Something Went Wrong");
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setResponse(error.message);
        toast.error("Something went Wrong");
        setIsloading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}
      <Toaster />
      <div className="card" style={{ background: "transparent", border: "none", height: "100vh" }}>
        <div className="d-flex h-100 align-items-center justify-content-center">
          <form
            id="uform"
            onSubmit={handlesubmit}
            className="row g-3 col-11 col-md-5 p-4 my-5 text-light rounded shadow-lg bg-none"
            // style={{ background: "#36393f" }}
            data-aos="fade-down"
          >
            <h3 className="fw-bold">Login</h3>
            {tresponse.length !== 0 && <p className="fw-bold text-info">{tresponse}</p>}
            <hr />
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control bg-none text-light"
                  style={{ background: "transparent" }}
                  id="Username"
                  autoComplete="user-name"
                  placeholder="Dave Jonas"
                  value={udata.username}
                  onChange={(e) => setUdata({ ...udata, username: e.target.value })}
                />
                <label for="Username" className="text-light">
                  Username
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control bg-none  text-light "
                  id="Password"
                  style={{ background: "transparent" }}
                  autoComplete="new-password"
                  placeholder="Minimum 8 characters"
                  value={udata.password}
                  onChange={(e) => setUdata({ ...udata, password: e.target.value })}
                />
                <label for="Password" className="text-light">
                  Password
                </label>
              </div>
            </div>

            <div className="col-12 py-2">
              <button
                type="submit"
                className="btn w-100 fw-bold py-2 btn-lg text-light"
                style={{
                  background: "radial-gradient(circle, rgba(254,97,97,1) 0%, rgba(255,48,48,1) 100%)",
                }}
              >
                Sign In
              </button>

              <p className="text-lead text-light text-center pt-5">Forgot your Password?</p>
              <div className="d-flex align-items-center justify-content-between w-100 my-3">
                <hr style={{ backgroundColor: "white", borderColor: "white", width: "28%" }} />
                <p className="py-0 my-0 mx-2">Or sign in with</p>
                <hr style={{ backgroundColor: "white", borderColor: "white", width: "28%" }} />
              </div>
              <div className="d-flex d-flex justify-content-between w-100">
                <a href="#" className="btn btn-outline-light w-100 me-5 rounded-3 py-2">
                  {" "}
                  Google
                </a>
                <a href="#" className="btn btn-outline-light w-100 rounded-3 py-2 text-primary">
                  {" "}
                  Facebook
                </a>
              </div>
            </div>
            <p className="lead">
              Don't have an Account?
              <Link to="/signup" state={{ check: "SignUp" }} className="link-info text-decoration-none fw-bold">
                {" "}
                Sign Up
              </Link>
            </p>
          </form>
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default Login;
