import logo from "./logo.svg";
import "./App.css";
import User from "./components/authentication/user";
import Main from "./components/main";
import UserDash from "./components/userdash";
import BtmNav from "./components/bottomnav";
import Spotify from "./components/auth";
import MiniPlayer from "./components/miniplayer";
import Player from "./components/player";
import { BrowserRouter as Router, Routes, Route, Location } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  // Audio Player vars
  const [trackid, setTrackId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [artistId, setArtistId] = useState("");
  const [artistName, setArtistName] = useState("");
  const [trackname, setTrackName] = useState("");

  const [trackpre, setTrackpre] = useState("");
  const [exter, setExtern] = useState("");
  const [trackimg, setTrackImg] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [uList, setPlaylist] = useState(false);
  const [crntListm, setCrntlist] = useState([]);
  const [origin, setOrigin] = useState("Main");
  const [gseekValue, setGSeekValue] = useState(0);

  // Audio Palyer vars End

  const [uimg, setUimg] = useState(localStorage.getItem("userProfile"));

  return (
    <Router>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/login" element={<User />} />
        <Route path="/signup" element={<User />} />
        <Route
          path="/main"
          element={
            <Main
              setdp={(e) => {
                setUimg(e);
              }}
              setGTrackpre={(e) => setTrackpre(e)}
              setGTrackId={(e) => setTrackId(e)}
              setGExtern={(e) => setExtern(e)}
              setGAlbumId={(e) => setAlbumId(e)}
              setGArtistId={(e) => setArtistId(e)}
              setGTrackImg={(e) => setTrackImg(e)}
              setGArtistName={(e) => setArtistName(e)}
              setGTrackName={(e) => setTrackName(e)}
            />
          }
        />
        <Route
          path="/user"
          element={
            <UserDash
              setdp={(e) => {
                setUimg(e);
              }}
              setGTrackpre={(e) => setTrackpre(e)}
              setGTrackId={(e) => setTrackId(e)}
              setGExtern={(e) => setExtern(e)}
              setGAlbumId={(e) => setAlbumId(e)}
              setGArtistId={(e) => setArtistId(e)}
              setGTrackImg={(e) => setTrackImg(e)}
              setGArtistName={(e) => setArtistName(e)}
              setGTrackName={(e) => setTrackName(e)}
            />
          }
        />
        <Route
          path="/search"
          element={
            <Spotify
              setGTrackpre={(e) => setTrackpre(e)}
              setGTrackId={(e) => setTrackId(e)}
              setGExtern={(e) => setExtern(e)}
              setGAlbumId={(e) => setAlbumId(e)}
              setGArtistId={(e) => setArtistId(e)}
              setGTrackImg={(e) => setTrackImg(e)}
              setGArtistName={(e) => setArtistName(e)}
              setGTrackName={(e) => setTrackName(e)}
            />
          }
        />
      </Routes>

      {window.location.pathname !== "/" && window.location.pathname !== "/login" && window.location.pathname !== "/signup" && (
        <>
          {console.log("Global")}
          {console.log(gseekValue)}
          {trackpre !== "" && (
            <div onClick={() => setOrigin("Main")}>
              <MiniPlayer
                GSeekValue={gseekValue}
                GTrackImg={trackimg}
                GTrackname={trackname}
                GArtistname={artistName}
                ogIsplaying={isPlaying}
                setGIsPlaying={(e) => setIsPlaying(e)}
                isTrack={trackpre}
              />
            </div>
          )}
          <div
            class="offcanvas offcanvas-bottom h-100 bg-dark border-0"
            tabindex="9"
            id="offcanvasPlayer"
            aria-labelledby="offcanvasPlayerLabel"
            style={{
              height: "80vh",
              zIndex: "99999",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url(${trackimg})`,
            }}
          >
            <div class="offcanvas-header d-block">
              <button type="button" className="btn btn-lg bg-none text-light ps-0" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setOrigin("Main")}>
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>

            <div class="offcanvas-body small">
              <Player
                setGSeekValue={(e) => setGSeekValue(e)}
                GTrackname={trackname}
                GArtistname={artistName}
                chkorigin={origin}
                setChkOrgin={(e) => setOrigin(e)}
                setGIsPlaying={(e) => setIsPlaying(e)}
                ogIsplaying={isPlaying}
                track={trackpre}
                external={exter}
                tid={trackid}
                aid={artistId}
                alid={albumId}
              />
            </div>
          </div>
        </>
      )}

      {window.location.pathname !== "/" && window.location.pathname !== "/login" && window.location.pathname !== "/signup" && <BtmNav dp={uimg} />}
    </Router>
  );
}

export default App;
