import React, { useState, useEffect, useRef } from "react";
import "./player.css";
import Slidercirlce from "./rater/slider";
import Followers from "./lists/followerlist";
import Comments from "./lists/comments";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";

const MiniPlayer = (props) => {
  return (
    <>
      {console.log("miniPalyer")}
      {console.log(Math.ceil(props.GSeekValue))}

      <div className="miniPlayer ">
        <div id="wrapper">
          <div id="screen">
            <div id="content-wrap">
              <div id="content" className="d-flex align-items-center bg-none">
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center fs-3 text-light w-75 mx-auto"></div>
                  {/* Player Controls Start===> */}

                  <>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div
                        className=" d-flex align-items-center text-truncate text-light"
                        style={{ width: "80%" }}
                      >
                        <img
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasPlayer"
                          aria-controls="offcanvasPlayer"
                          src={props.GTrackImg}
                          className="rounded me-1"
                          style={{ width: "50px", aspectRatio: "1", objectFit: "cover" }}
                        />
                        <span>
                          <p className="fs-6 my-0 py-0">{props.GTrackname}</p>
                          <p className="my-0 py-0 fw-light opacity-75" style={{ fontSize: "12px" }}>
                            {props.GArtistname}
                          </p>
                        </span>
                      </div>
                      <div
                        id="controls"
                        className="mb-0 pb-0 mt-0 w-100  d-flex justify-content-end align-items-center pe-2"
                      >
                        <i
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasPlayer"
                          aria-controls="offcanvasPlayer"
                          className="fa fa-bar-chart fa-fw fs-2 "
                          aria-hidden="true"
                        ></i>

                        {props.isTrack !== "no-track" && (
                          <span id="play-btn" className="ms-5">
                            {props.ogIsplaying ? (
                              <i
                                className="fa fa-pause fa-fw fs-2"
                                aria-hidden="true"
                                onClick={() => props.setGIsPlaying(false)}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-play fa-fw fs-2"
                                aria-hidden="true"
                                onClick={() => props.setGIsPlaying(true)}
                              ></i>
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    <div id="timeline" className="mt-2">
                      <div className="slider">
                        <div className="track">
                          <div
                            className="progress"
                            style={{ width: `${Math.ceil(props.GSeekValue)}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </>

                  {/* Player Controlls End===> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniPlayer;
