import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import FeedCard from "./feedcards";
import Playcard from "./playcards";
import Player from "./player";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Followers from "./lists/followerlist";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const SearchWindow = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);

  const [searchType, setSearchType] = useState("Playlist");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [trackid, setTrackId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [artistId, setArtistId] = useState("");

  const [trackpre, setTrackpre] = useState("");
  const [exter, setExtern] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [playlists, setUplaylist] = useState([]);

  const [uList, setPlaylist] = useState(false);
  const [crntListm, setCrntlist] = useState([]);

  // Profiles Variabels starts===>
  const [currentProfile, setCurrentProfile] = useState("");
  const [cTracks, setCtracks] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [chkFollow, setChkFollow] = useState(1);
  const [isFollowing, setIsFollowing] = useState(false);
  const [totalReviews, setTotalReviews] = useState(0);
  const [followerlist, setFollowerList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [toggleFerList, setFerlist] = useState(false);
  const [toggleFollowingList, setToggleFollowingList] = useState(false);
  const [isLoadingsm, setIsloadingsm] = useState(false);

  useEffect(() => {
    const config = {
      headers: { authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/auth/validate-user/", {}, config)
      .then((response) => console.log("ok"))
      .catch(() => {
        removeCookie("uToken");
        window.location.href = "/";
      });
  }, []);

  // Check Following Start====================>
  function checkFollowerInFollowing(cuser, userData) {
    for (let i = 0; i < userData.length; i++) {
      if (userData[i].user === cuser) {
        for (let j = 0; j < userData[i].following.length; j++) {
          if (userData[i].following[j].includes(currentProfile.name)) {
            console.log("following");
            return true;
          }
        }
      }
    }
    return false;
  }

  // Check Following End====================>

  const handleFollow = () => {
    setIsloading(true);
    const config = {
      headers: { authorization: cookies.uToken },
    };

    console.log("FOLLOW DATA-------");
    console.log(currentProfile);
    Axios.post("https://api.critiq.se/user/add_following", JSON.stringify(currentProfile), config)
      .then((response) => {
        // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);

        setIsloading(false);
        toast.success("Success!");
        setChkFollow(chkFollow + 1);
        setCurrentProfile({
          ...currentProfile,
          followers: isFollowing ? currentProfile.followers - 1 : currentProfile.followers + 1,
        });
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/user/get-alluser-profiles", { is_tracks: true }, config)
      .then((response) => {
        // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);
        // console.log(JSON.parse(response.data.users));
        setProfiles(JSON.parse(response.data.users));
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  }, []);

  useEffect(() => {
    setIsloadingsm(true);

    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/user/get-profile", { is_tracks: true, spotify_id: currentProfile.spotify_id ? currentProfile.spotify_id : "" }, config)
      .then((response) => {
        console.log(response.data);
        setCtracks(response.data.track_data !== undefined ? response.data.track_data : "no-data");
        setIsloadingsm(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloadingsm(false);
      });
  }, [currentProfile.spotify_id]);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/user/get-all-followers", {}, config)
      .then((response) => {
        setFollowerList(
          response.data
            .filter((e) => e.user == currentProfile.name)
            .map((ini) => {
              return ini.followers;
            })
        );
        setFollowingList(
          response.data
            .filter((e) => e.user == currentProfile.name)
            .map((ini) => {
              return ini.following;
            })
        );
        setIsFollowing(checkFollowerInFollowing(localStorage.getItem("cUser"), response.data));
      })
      .catch(() => {
        toast.error("Something Went wrong!");
      });
  }, [chkFollow, currentProfile]);

  // Profiles Variables end ===>
  const handleSearch = async (e) => {
    e.preventDefault();

    if (searchType == "Tracks") {
      setPlaylist(false);
      setCrntlist([]);
      setIsloading(true);
      try {
        const response = await fetch(`https://api.spotify.com/v1/search?q=${searchTerm}&type=track`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSearchResults(data.tracks.items);
          setIsloading(false);
        } else {
          console.error("Failed to fetch search results");
          localStorage.removeItem("token");
          window.location.reload();
          setIsloading(false);
        }
      } catch (error) {
        console.error("Error occurred during search", error);
        localStorage.removeItem("token");
        window.location.reload();
        setIsloading(false);
      }
    } else if (searchType == "Users") {
      const config = {
        headers: { authorization: cookies.uToken },
      };

      // setTresponse("");
      Axios.post("https://api.critiq.se/user/get-alluser-profiles", {}, config)
        .then((response) => {
          // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);
          setProfiles(JSON.parse(response.data.users));
          setIsloading(false);
          console.log("------***----9999");
          console.log(JSON.parse(response.data));

          console.log(JSON.parse(response.data.users));
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false);
        });
    }
  };

  const getPlayListData = (e) => {
    console.log(e);
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };
    Axios.post("https://api.critiq.se/playlist/get-playlists-data", { playlist_id: e }, config)
      .then((response) => {
        console.log("==TRACKS==========");
        console.log(response.data.playlist_tracks);
        setCrntlist(response.data.playlist_tracks);
        setPlaylist(true);
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
        toast.error("Something Went wrong /get-playlists-data ");
      });
    // setCrntlist(ini.tracks.items);
  };

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json", authorization: cookies.uToken },
    };

    Axios.post("https://api.critiq.se/playlist/get-playlists", {}, config).then((response) => {
      console.log("========+==========");
      console.log(JSON.parse(response.data.playlists));
      setUplaylist(JSON.parse(response.data.playlists));
    });
  }, []);

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}

      <div className="bg-black pt-5">
        <div className="container">
          <div className="row position-sticky top-0 bg-black py-3">
            <div className="col-12">
              <form className="d-flex pe-0 w-100 mx-auto shadow-lg" onSubmit={handleSearch}>
                <input
                  className="form-control text-light rounded-5 rounded-end bg-dark border-0 ps-5 py-2 shadow-sm"
                  type="search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button className="btn text-light rounded-0 rounded-end  ms-2 position-absolute" type="submit">
                  <i className="fa fa-search"></i>
                </button>
                {!uList && (
                  <select
                    className="form-select bg-dark text-light border-0 text-center w-25 ms-2 rounded-5 rounded-start bg-dark py-2 shadow-sm"
                    placeholder="Sort"
                    // style={{ color: "rgba(255,255,255,0.1)" }}
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option selected value="Playlist">
                      Playlist
                    </option>
                    <option value="Tracks">Tracks</option>
                    <option value="Users">Users</option>
                  </select>
                )}
              </form>
            </div>
          </div>

          {/* Search Type Playlist start=====> */}
          {searchType == "Playlist" && (
            <>
              <div>
                {!uList && playlists.length !== 0 && (
                  <>
                    <div className="display-4 mt-5 pb-3 text-light">
                      Playlists <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="row">
                      {playlists
                        .filter((e) => e.title.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map((ini) => {
                          return (
                            <motion.div
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: false }}
                              transition={{ duration: 0.3 }}
                              variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
                              className="col-6"
                            >
                              <span onClick={() => getPlayListData(ini.playlist_id)}>
                                <Playcard id={ini.playlist_id} img={ini.image} pname={ini.title} uname={localStorage.getItem("cUser")} />
                              </span>
                            </motion.div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
              {uList && (
                <>
                  <i className="fa fa-angle-left display-3 text-light mb-0" style={{ cursor: "pointer" }} onClick={() => setPlaylist(false)}></i>
                  <div
                    className="row mt-2 px-3 hideYscroll"
                    style={{
                      overflowY: "scroll",
                      marginBottom: "100px",
                      paddingBottom: "200px",
                    }}
                  >
                    {crntListm
                      .filter((e) => e.track.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((result) => (
                        <div
                          className="col-12 d-flex align-items-center justify-content-start p-3 mb-3 rounded bg-dark text-light text-wrap"
                          key={result.track.id}
                          onClick={() => {
                            console.log(props.props);
                            props.props.setGTrackpre(result.track.preview_url !== null ? result.track.preview_url : "no-track");
                            props.props.setGTrackId(result.track.id);
                            props.props.setGTrackName(result.track.name);
                            props.props.setGArtistName(result.track.album.artists[0].name);
                            props.props.setGAlbumId(result.track.album.id);
                            props.props.setGArtistId(result.track.album.artists[0].id);
                            props.props.setGExtern(result.track.external_urls.spotify);
                            props.props.setGTrackImg(result.track.album.images[0].url);

                            // MINI player song data
                            localStorage.setItem("trackPreview", result.track.preview_url !== null ? result.track.preview_url : "no-track");
                            localStorage.setItem("trackImg", result.track.album.images[0].url);

                            // MINI player song data
                          }}
                          style={{ cursor: "pointer", maxHeight: "80px" }}
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasPlayer"
                          aria-controls="offcanvasPlayer"
                          aria-hidden="true"
                        >
                          <img
                            src={result.track.album.images.length !== 0 ? result.track.album.images[0].url : "logo192.png"}
                            className=" rounded-circle me-3"
                            style={{ objectFit: "cover", height: "50px", aspectRatio: 1 }}
                          />{" "}
                          <span className="fw-bold me-3">{result.track.name}</span>
                          <span className="fw-bold">{result.track.artists[0].name}</span>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          )}
          {/* Search Type Playlist end=====> */}

          {/* Search Type Tarcks start=====> */}
          {searchType == "Tracks" && (
            <>
              {searchTerm.length !== 0 && searchResults.length !== 0 && (
                <>
                  <i className="fa fa-times-circle fs-2 text-light" onClick={() => setSearchTerm("")}></i>
                  <div className="row mt-2 px-2" style={{ paddingBottom: "200px" }}>
                    {searchResults.map((result) => (
                      <div
                        className="col-12 d-flex align-items-center justify-content-start p-3 mb-3 rounded bg-dark text-light"
                        key={result.id}
                        onClick={() => {
                          props.props.setGTrackpre(result.preview_url !== null ? result.preview_url : "no-track");
                          props.props.setGTrackId(result.id);
                          props.props.setGTrackName(result.name);
                          props.props.setGArtistName(result.album.artists[0].name);
                          props.props.setGAlbumId(result.album ? result.album.id : "");
                          props.props.setGArtistId(result.album ? result.album.artists[0].id : "");
                          props.props.setGExtern(result.external_urls.spotify);
                          props.props.setGTrackImg(result.album.images[0].url);

                          // MINI player song data
                          localStorage.setItem("trackPreview", result.preview_url !== null ? result.preview_url : "no-track");
                          localStorage.setItem("trackImg", result.album.images[0].url);

                          // MINI player song data
                        }}
                        style={{ cursor: "pointer" }}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasPlayer"
                        aria-controls="offcanvasPlayer"
                        aria-hidden="true"
                      >
                        <img src={result.album.images[0].url} className=" rounded-circle me-3" style={{ objectFit: "cover", height: "50px", aspectRatio: 1 }} />{" "}
                        <span className="fw-bold me-3">{result.name}</span>
                        <span className="fw-bold">{result.artists[0].name}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          {/* Search Type Tracks end=====> */}

          {/* Search Type Users start=====> */}
          {searchType == "Users" && (
            <div style={{ paddingBottom: "220px" }}>
              {profiles
                .filter((e) => e.user.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((ini) => {
                  return (
                    <div
                      className="row d-flex align-items-center mx-0 py-3 px-2 mb-3 rounded-4 bg-dark text-light"
                      onClick={(e) => {
                        setCurrentProfile({
                          name: ini.user,
                          spotify_id: ini.spotify_id,
                          followers: ini.followers,
                          following: ini.following,
                          spotify: ini.spority_href,
                          username: ini.name,
                          user_profile: ini.profile_picture,
                        });
                      }}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom2"
                      aria-controls="offcanvasBottom"
                    >
                      <div className="col-lg-2 col-3 ps-0  text-center" style={{ borderRight: "2px solid rgba(255,255,255,0.2)" }}>
                        <img
                          style={{ height: "60px", aspectRatio: 1, objectFit: "cover" }}
                          className="rounded-circle"
                          src={
                            ini.profile_picture !== ""
                              ? `https://api.critiq.se/media/` + ini.profile_picture
                              : "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                          }
                        />
                      </div>
                      <div className="col-8 text-start ps-2">
                        <h2 className="fs-4  mb-0 pb-1">{ini.user}</h2>
                        <p className="my-0 py-0 text-secondary">
                          {ini.name} - {ini.followers} followers
                        </p>
                      </div>

                      <div className="col-lg-2 text-start d-lg-block d-none">
                        <h1 className="fs-4 opacity-75"> {ini.followers} followers</h1>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {/* Search Type Users End=====> */}
        </div>

        {profiles && (
          <>
            <div
              class="offcanvas  offcanvas-bottom bg-dark text-light"
              tabindex="-1"
              id="offcanvasBottom2"
              aria-labelledby="offcanvasBottomLabel2"
              style={{ height: "90vh", borderTopRightRadius: "30px", borderTopLeftRadius: "30px" }}
            >
              <div class="offcanvas-header">
                {isLoading ? (
                  <button disabled="true" className="btn btn-secondary rounded-pill px-3 " onClick={handleFollow}>
                    Processing <i className="fa fa-cog fa-spin fs-5 align-middle "></i>
                  </button>
                ) : (
                  <>
                    {!isFollowing ? (
                      <button className="btn btn-light rounded-pill px-3 " onClick={handleFollow}>
                        Follow <i className="fa fa-plus-circle fs-5 align-middle ps-2"></i>
                      </button>
                    ) : (
                      <button className="btn btn-outline-danger rounded-pill px-3 " onClick={handleFollow}>
                        Unfollow <i className="fa fa-minus-circle fs-5 align-middle ps-2"></i>
                      </button>
                    )}
                  </>
                )}

                <button type="button" class="btn btn-lg bg-none border-0 text-light" data-bs-dismiss="offcanvas">
                  <i className="fa fa-angle-down fs-2"></i>
                </button>
              </div>
              <div class="offcanvas-body large">
                <div className="h-100">
                  <div className="container">
                    <div className="row d-flex">
                      <div className="col-lg-3 d-flex align-items-center justify-content-center">
                        <img
                          className="rounded-circle"
                          style={{ height: "100px", width: "100px", objectFit: "cover" }}
                          src={
                            currentProfile.user_profile !== ""
                              ? `https://api.critiq.se/media/` + currentProfile.user_profile
                              : "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                          }
                        />
                      </div>
                      <div className="col-lg-8" style={{ borderBottom: "1px solid rgba(255,255,255,0.5)" }}>
                        <div className="d-lg-flex align-items-center h-100">
                          <div className="bg-dark bg-opacity-50 p-lg-4 p-3 rounded text-lg-start text-center text-light w-100">
                            <h1 className="display-6">{currentProfile.name}</h1>
                            <p className="text-lead">@{currentProfile.username}</p>
                            <div className="d-flex w-100 justify-content-between">
                              <span className="text-center">
                                <h3 className="fw-bold">{cTracks !== undefined && cTracks !== "no-data" && cTracks.length}</h3>
                                <p className="fs-6 fw-light opacity-75">Review</p>
                              </span>
                              <span className="text-center" style={{ cursor: "pointer" }} onClick={() => setFerlist(true)}>
                                <h3 className="fw-bold">{currentProfile.followers}</h3>
                                <p className="fs-6 fw-light opacity-75">Followers</p>
                              </span>
                              <span className="text-center" style={{ cursor: "pointer" }} onClick={() => setToggleFollowingList(true)}>
                                <h3 className="fw-bold">{currentProfile.following}</h3>
                                <p className="fs-6 fw-light opacity-75">Following</p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container  h-100 ">
                    <h5 className="fw-bold text-light text-center pb-2 mt-2">Top Rated Tracks</h5>

                    {isLoadingsm && (
                      <div style={{ height: "100%", width: "100%" }} className="d-flex align-items-start mt-4">
                        <div class="loader2 mx-auto"></div>
                      </div>
                    )}

                    {console.log("Current profile")}
                    {console.log(cTracks)}

                    {!isLoadingsm && cTracks !== undefined && cTracks !== "no-data" && (
                      <div className="pt-3 hidescroll" style={{ height: "60vh", overflowY: "auto", paddingBottom: "100px" }}>
                        {cTracks.map((ini, i) => {
                          return <FeedCard id={i} origin="profiles" trackRecord={ini} props={props.props} />;
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Follower List */}
              {toggleFerList && (
                <div
                  class=" h-100 bg-black border-0"
                  style={{
                    height: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: "99",
                  }}
                  data-aos="fade-up"
                >
                  <div class="offcanvas-header d-block">
                    <button type="button" className="btn btn-lg bg-none text-light ps-0" onClick={() => setFerlist(false)}>
                      <i className="fa fa-chevron-down"></i>
                    </button>
                  </div>
                  {followerlist.length !== 0 && (
                    <div className="container p-3">
                      {followerlist[0].map((ini) => {
                        return <Followers follower={ini} origin="profiles" />;
                      })}
                    </div>
                  )}
                </div>
              )}
              {/* Follower List END */}
              {/* Following List */}
              {toggleFollowingList && (
                <div
                  class=" h-100 bg-black border-0"
                  style={{
                    height: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: "99",
                  }}
                  data-aos="fade-up"
                >
                  <div class="offcanvas-header d-block">
                    <button type="button" className="btn btn-lg bg-none text-light ps-0" onClick={() => setToggleFollowingList(false)}>
                      <i className="fa fa-chevron-down"></i>
                    </button>
                  </div>
                  {followingList.length !== 0 && (
                    <div className="container p-3">
                      {followingList[0].map((ini) => {
                        return <Followers follower={ini} origin="profiles" />;
                      })}
                    </div>
                  )}
                </div>
              )}
              {/* Following List END */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchWindow;
