import React from "react";
import { motion } from "framer-motion";
const FeedCard = (props) => {
  if (props.origin == "home") {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.3 }}
        variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
        className={`card bg-dark text-white p-0 border-0 pb-2 ${props.opened ? "mb-3" : "mb-1"} me-4`}
        style={{ display: props.opened ? "block" : "inline-block", width: props.opened ? "100%" : "15rem", cursor: "pointer" }}
        onClick={() => {
          console.log(props.props);
          props.props.setGTrackpre(props.res.preview_url !== null ? props.res.preview_url : "no-track");
          props.props.setGTrackId(props.res.id);
          props.props.setGTrackName(props.res.name);
          props.props.setGArtistName(props.res.artists.name);
          props.props.setGAlbumId(props.res.id);
          props.props.setGArtistId(props.res.artists.id);
          props.props.setGExtern(props.res.external_urls.spotify);
          props.props.setGTrackImg(props.res.images.url);

          // MINI player song data
          localStorage.setItem("trackPreview", props.res.preview_url !== null ? props.res.preview_url : "no-track");
          localStorage.setItem("trackImg", props.res.images.url);

          // MINI player song data
        }}
      >
        <img src={props.res.images.url} className="card-img  opacity-50" style={{ objectFit: "cover", height: "270px" }} loading="lazy" alt="..." />
        <div className="card-img-overlay p-0 ">
          <div className="clipVid row mx-0 px-0">
            <div className="col-8 ps-0 pe-2">
              <div className="w-100 rounded-2 bg-white mb-2">
                <span className="d-flex align-items-center justify-content-between  text-light p-2" style={{ background: "rgba(0,0,0,0.5)" }}>
                  <div className="d-flex align-items-center">
                    <img
                      style={{ height: "25px", aspectRatio: 1, objectFit: "cover" }}
                      className="rounded-circle me-2 border-1 border border-light"
                      src={`https://api.critiq.se/media/` + props.res.user_profile}
                    />
                    <p className="p-0 m-0 me-2">{props.res.user}</p>
                  </div>
                  <p className="fs-4 p-0 m-0"> {props.res.ratings}</p>
                </span>
              </div>

              <div className="w-100 rounded-2 bg-white  mb-2">
                <span className="text-light p-2 d-flex align-items-end" style={{ background: "rgba(0,0,0,0.5)", height: props.opened ? "150px" : "120px" }}>
                  <div className="h-100">
                    <p className="fs-3 fw-bold p-0 m-0 text-wrap  text-truncate" style={{ maxHeight: "70%" }}>
                      {props.res.name}
                    </p>
                    <p className="fs-6   p-0 m-0"> {props.res.artists.name}</p>
                  </div>
                </span>
              </div>

              <div className="w-100 bg-white  mb-2" style={{ borderRadius: "5px 5px 5px 25px" }}>
                <span className="text-light p-2 d-block" style={{ background: "rgba(0,0,0,0.5)", height: props.opened ? "57px" : "auto" }}>
                  <div className="d-flex flex-wrap-reverse">
                    <button className="btn btn-sm btn-outline-light me-2 mb-1"> Rock</button>
                    <button className="btn btn-sm btn-outline-light me-2 mb-1"> Pop</button>
                    <button className="btn btn-sm btn-outline-light me-2 mb-1"> Alt-Rock</button>
                  </div>
                </span>
              </div>
            </div>

            <div className="col-4 px-0">
              <div className="w-100 bg-white mb-2" style={{ borderRadius: "5px 25px 5px 5px" }}>
                <span className="d-flex align-items-center justify-content-between  text-light p-2" style={{ background: "rgba(0,0,0,0.5)", height: "84px" }}>
                  <div className="d-block text-center w-100">
                    <i className="fa fa-bar-chart fs-3"></i>
                    <p className="fs-5 p-0 m-0"> 8.9</p>
                  </div>
                </span>
              </div>

              <div className="w-100 bg-white mb-2" style={{ borderRadius: "5px 5px 5px 5px" }}>
                <span className="d-flex align-items-center justify-content-between  text-light p-2 " style={{ background: "rgba(0,0,0,0.5)", height: "84px" }}>
                  <div className="d-block text-center w-100">
                    <i className="fa fa-users fs-3"></i>
                    <p className="fs-5 p-0 m-0"> {props.res.total_reviews}</p>
                  </div>
                </span>
              </div>

              <div className="w-100 bg-white mb-2" style={{ borderRadius: "5px 5px 5px 5px" }}>
                <span className="d-flex align-items-center justify-content-between  text-light p-2" style={{ background: "rgba(0,0,0,0.5)", height: "85px" }}>
                  <div className="d-block text-center w-100">
                    <i className="fa fa-music fs-3"></i>
                    <p className="fs-6 p-0 m-0"> Alt-Rock</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  } else if (props.origin == "tophome") {
    return (
      <motion.div
        onClick={() => {
          props.props.setGTrackpre(props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
          props.props.setGTrackId(props.trackRecord.id);
          props.props.setGTrackName(props.trackRecord.name);
          props.props.setGArtistName(props.trackRecord.artists.name);
          props.props.setGAlbumId(props.trackRecord.id);
          props.props.setGArtistId(props.trackRecord.artists.id);
          props.props.setGExtern(props.trackRecord.external_urls.spotify);
          props.props.setGTrackImg(props.trackRecord.images.url);

          // MINI player song data
          localStorage.setItem("trackPreview", props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
          localStorage.setItem("trackImg", props.trackRecord.images.url);

          // MINI player song data
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.3 }}
        variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 0.7 } }}
        className="card bg-black text-white w-100 mb-2 py-2 border-0"
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="text-white display-6" style={{ width: "50px" }}>
            {props.id + 1}
          </h1>

          <img src={props.trackRecord.images.url} className="card-img resimg rounded-4 border border-2 me-3 " style={{ objectFit: "cover", width: "60px", height: "60px" }} loading="lazy" alt="..." />

          <div className="w-75 text-truncate">
            <h6 className="card-title fw-bold w-75 text-truncate" style={{ fontSize: "17px" }}>
              {props.trackRecord.name}
            </h6>
            <h6 className="" style={{ fontSize: "15px" }}>
              {props.trackRecord.artists.name}
            </h6>
          </div>
          <div style={{ maxWidth: "100px" }} className="text-center">
            <p className="fw-bold pb-0 mb-0" style={{ fontSize: "16px" }}>
              {props.trackRecord.avg_ratings.toFixed(1)}
            </p>
            <p className="pt-0 mt-0" style={{ fontSize: "14px" }}>
              {"(" + props.trackRecord.total_reviews + ")"}
            </p>
          </div>
        </div>
      </motion.div>
    );
  } else if (props.origin == "profiles") {
    return (
      <div className="card bg-dark text-white w-100 mb-5">
        <img src={props.trackRecord.images.url} className="card-img resimg" style={{ objectFit: "cover", height: "100%" }} loading="lazy" alt="..." />
        <div className="card-img-overlay">
          <div className="d-flex align-items-center justify-content-between">
            <p className="fs-4 fw-bold">
              <i className="fa fa-bar-chart pe-3"></i>
              {props.trackRecord.rating.toFixed(1)}
            </p>
          </div>
          <span className="d-flex align-items-center "></span>
          <div className="d-flex justify-content-between">
            <div className="w-75 text-truncate">
              <h5 className="card-title fw-bold mt-3 w-50 text-truncate">{props.trackRecord.name}</h5>
              <h6 className="fw-bold">{props.trackRecord.artists.name}</h6>
            </div>
            <div style={{ maxWidth: "100px" }}>
              <a href={props.trackRecord.external_urls.spotify} target="_blank" className="btn btn-danger rounded-pill d-block w-100 mb-2 btn-sm">
                Play <i className="fa fa-spotify ms-1"></i>
              </a>
              <button
                className="btn btn-light rounded-pill d-block w-100 btn-sm"
                onClick={() => {
                  props.props.setGTrackpre(props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
                  props.props.setGTrackId(props.trackRecord.id);
                  props.props.setGTrackName(props.trackRecord.name);
                  props.props.setGArtistName(props.trackRecord.artists.name);
                  props.props.setGAlbumId(props.trackRecord.id);
                  props.props.setGArtistId(props.trackRecord.artists.id);
                  props.props.setGExtern(props.trackRecord.external_urls.spotify);
                  props.props.setGTrackImg(props.trackRecord.images.url);

                  // MINI player song data
                  localStorage.setItem("trackPreview", props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
                  localStorage.setItem("trackImg", props.trackRecord.images.url);

                  // MINI player song data
                }}
              >
                Open
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.origin == "profiles-recent") {
    return (
      <div className="card bg-dark text-white w-100 mb-5">
        <img src={props.trackRecord.images.url} className="card-img resimg" style={{ objectFit: "cover", height: "100%" }} loading="lazy" alt="..." />
        <div className="card-img-overlay">
          <div className="d-flex align-items-center justify-content-between">
            <p className="fs-4 fw-bold">
              <i className="fa fa-bar-chart pe-3"></i>
              {/* {props.trackRecord.rating.toFixed(1)} */}
            </p>
          </div>
          <span className="d-flex align-items-center "></span>
          <div className="d-flex justify-content-between">
            <div className="w-75 text-truncate">
              <h5 className="card-title fw-bold mt-3 w-50 text-truncate">{props.trackRecord.name}</h5>
              <h6 className="fw-bold">{props.trackRecord.artists.name}</h6>
            </div>
            <div style={{ maxWidth: "100px" }}>
              <a href={props.trackRecord.external_urls.spotify} target="_blank" className="btn btn-danger rounded-pill d-block w-100 mb-2 btn-sm">
                Play <i className="fa fa-spotify ms-1"></i>
              </a>
              <button
                className="btn btn-light rounded-pill d-block w-100 btn-sm"
                onClick={() => {
                  props.props.setGTrackpre(props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
                  props.props.setGTrackId(props.trackRecord.id);
                  props.props.setGTrackName(props.trackRecord.name);
                  props.props.setGArtistName(props.trackRecord.artists.name);
                  props.props.setGAlbumId(props.trackRecord.id);
                  props.props.setGArtistId(props.trackRecord.artists.id);
                  props.props.setGExtern(props.trackRecord.external_urls.spotify);
                  props.props.setGTrackImg(props.trackRecord.images.url);

                  // MINI player song data
                  localStorage.setItem("trackPreview", props.trackRecord.preview_url !== null ? props.trackRecord.preview_url : "no-track");
                  localStorage.setItem("trackImg", props.trackRecord.images.url);

                  // MINI player song data
                }}
              >
                Open
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="card bg-dark text-white w-100 mb-5">
        <img src="https://media.tenor.com/G7X1OhZspZwAAAAd/logic-no-pressure-album.gif" className="card-img resimg" style={{ objectFit: "cover", height: "100%" }} loading="lazy" alt="..." />
        <div className="card-img-overlay">
          <div className="d-flex align-items-center justify-content-between">
            <p className="fs-4 fw-bold">
              <i className="fa fa-bar-chart pe-3"></i>8.8
            </p>
          </div>
          <span className="d-flex align-items-center ">
            <img
              style={{ height: "25px", aspectRatio: 1, objectFit: "cover" }}
              className="rounded-circle me-2 border-1 border border-light"
              src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
            />
            <p className="p-0 m-0">John Martin</p>
          </span>
          <div className="d-flex justify-content-between">
            <div className="w-75 text-truncate">
              <h5 className="card-title fw-bold mt-3 w-50 text-truncate">No Pressure</h5>
              <h6 className="fw-bold">Logic</h6>
            </div>
            <div style={{ maxWidth: "100px" }}>
              <a href="https://open.spotify.com/album/5pF05wJrbrIvqunE41vWP8" target="_blank" className="btn btn-danger rounded-pill d-block w-100 mb-2 btn-sm">
                Play <i className="fa fa-spotify ms-1"></i>
              </a>
              <button className="btn btn-light rounded-pill d-block w-100 btn-sm">Open</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FeedCard;
