import React, { useState } from "react";
import CircularSlider from "@fseehawer/react-circular-slider";
import "./slider.css";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";

import Followers from "../lists/followerlist";

const Slidercirlce = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);

  const [value, setValue] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [udata, setUrating] = useState({
    user_id: 2,
    track_id: props.trackid,
    artist_id: props.artistId,
    album_id: props.albumId,
    rating: props.urating,
    review: "",
  });

  const handleChange = (event) => {
    setValue(parseInt(event.target.value, 10));
  };

  const handlesubmit = (e) => {
    setIsloading(true);

    e.preventDefault();
    // window.location.href = "/main";
    // const FD = new FormData(document.getElementById("uform"));
    // let formData = new FormData(); //formdata object

    // FD.append("username", udata.username);
    // FD.append("upw", udata.password); //append the values with key, value pair
    // // FD.append('age', 20);
    const config = {
      headers: { "content-type": "application/json", Authorization: cookies.uToken },
    };
    console.log(udata);

    // setTresponse("");
    Axios.post("https://api.critiq.se/tracks/add-rating", JSON.stringify(udata), config)
      .then((response) => {
        // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);
        toast.success("Success");
        setIsloading(false);
        console.log(response.data);
        props.recrate(props.ogrec + 1);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went Wrong");
        setIsloading(false);
      });
  };

  return (
    <>
      <Toaster />
      {console.log(props.urating)}
      {isLoading && (
        <div className="loader">
          <div class="loader2 mx-auto"></div>
        </div>
      )}

      <div style={{ color: "#e74c3c" }}>
        <CircularSlider
          width={150}
          label=" "
          labelColor="#e74c3c"
          knobColor="#e74c3c"
          progressColorFrom="#e74c3c"
          progressColorTo="#e74c3c"
          progressSize={8}
          trackColor="white"
          trackSize={6}
          data={[
            1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6,
            4.7, 4.8, 4.9, 5.0, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6.0, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3,
            8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0,
          ]}
          dataIndex={props.urating !== "" ? props.urating * 10 - 10 : 49}
          onChange={(value) => {
            setUrating({ ...udata, rating: value });
          }}
        />

        <div className="w-100 d-block text-center  d-flex align-items-center justify-content-between mt-4">
          <input
            type="text"
            className="form-control bg-dark w-100 me-2 text-light border-0"
            placeholder="Review..."
            value={udata.review}
            onChange={(e) => {
              setUrating({ ...udata, review: e.target.value });
            }}
          />

          <button className="btn px-1 text-light rounded-3  w-50" style={{ borderColor: "#e74c3c", fontSize: "16px" }} onClick={handlesubmit}>
            Confirm Score
          </button>
        </div>
      </div>
    </>
  );
};

export default Slidercirlce;
