import React, { useState, useEffect, useRef } from "react";
import "./player.css";
import Slidercirlce from "./rater/slider";
import Followers from "./lists/followerlist";
import Comments from "./lists/comments";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";

const Slider = ({ value, onChange }) => {
  const trackRef = useRef(null);

  const handleSliderChange = (e) => {
    const trackWidth = trackRef.current.offsetWidth;
    const offsetX = e.nativeEvent.offsetX;
    const percentage = (offsetX / trackWidth) * 100;
    onChange(percentage);
  };

  return (
    <div className="slider" onClick={handleSliderChange}>
      <div className="track" ref={trackRef}>
        <div className="progress" style={{ width: `${value}%` }}></div>
        <div className="thumb" style={{ left: `${value}%` }}></div>
      </div>
    </div>
  );
};

const Player = (props) => {
  // Ratings Variables
  const [cRbtn, setcRbtn] = useState("a");
  const [sharePrompt, setSharePropmt] = useState(false);
  const [userRating, setUserrating] = useState(0);
  const [avgRating, setAvgrating] = useState(0);
  const [recallRating, setRecallRating] = useState(0);
  // Ratings Variabels End

  // Comment Variables
  const [disab, setDisable] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [commentPrompt, setCommentPrompt] = useState(false);
  const [allComments, setAllcomment] = useState([]);
  const [followingComment, setFollowingcomment] = useState([]);
  const [cmntCount, setcmntCount] = useState(0);
  const [commRbtn, setcommRbtn] = useState("a");
  const [uComment, setUcomment] = useState({
    track_id: props.tid,
    comment: "",
  });

  function handleCommentSubmit(e) {
    setDisable(true);
    e.preventDefault();
    const config = {
      headers: { authorization: cookies.uToken },
    };
    console.log(config);
    const tdta = { track_id: props.tid };
    console.log("````````----");
    console.log(JSON.stringify(tdta));

    Axios.post("https://api.critiq.se/tracks/add-comment", JSON.stringify(uComment), config)
      .then((response) => {
        toast.success("Success");
        setDisable(false);
        setUcomment({
          track_id: props.tid,
          comment: "",
        });
        setcmntCount(cmntCount + 1);

        Axios.post("https://api.critiq.se/tracks/get-track-details", JSON.stringify(tdta), config)
          .then((response) => {
            // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);

            // setIsloading(false);
            console.log("Optimized----Version");
            console.log(response.data);

            setAllcomment(response.data[0].comments);

            setFollowingcomment(response.data[0].following_comments);

            setAvgrating(response.data[0].avg_ratings.toFixed(1));
            setUserrating(response.data[0].current_user_rating);
          })
          .catch((error) => {
            console.log(error);
            setDisable(false);
            toast.error("Something went wrong /get-track-details");
          });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went Wrong /Add-comment");
        setDisable(false);
      });
  }

  useEffect(() => {
    const config = {
      headers: { authorization: cookies.uToken },
    };
    console.log(config);

    setAllcomment([]);
    setFollowingcomment([]);
    setAvgrating(0);
    setUserrating(0);
    setcmntCount(0);
    setRecallRating(0);

    if (props.tid !== "") {
      const tdta = { track_id: props.tid };
      console.log("````````----");
      console.log(JSON.stringify(tdta));

      Axios.post("https://api.critiq.se/tracks/get-track-details", JSON.stringify(tdta), config)
        .then((response) => {
          // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);

          // setIsloading(false);
          console.log("Optimized----Version");
          console.log(response.data);

          setAllcomment(response.data[0].comments);

          setFollowingcomment(response.data[0].following_comments);

          setAvgrating(response.data[0].avg_ratings.toFixed(1));

          setUserrating(response.data[0].current_user_rating);
        })
        .catch((error) => {
          console.log(error);
          console.log("Something went wrong /get-track-details");
          // toast.error("Something went wrong /get-track-details");
          // setIsloading(false);
          setAllcomment([]);
          setFollowingcomment([]);
          setAvgrating(0);
          setUserrating(0);
          setcmntCount(0);
          setRecallRating(0);
        });
    }
  }, [cmntCount, 1, commentPrompt, props.tid, recallRating]);

  // Comment Variables End

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDurationLoaded, setIsDurationLoaded] = useState(false);
  const [seekValue, setSeekValue] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [stoggle, setToggle] = useState(false);
  const [audioSource, setAudio] = useState(props.track);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);
  const prevAudioRef = useRef(null);
  const prevTrack = useRef(null);
  const shouldPlay = useRef(false);

  useEffect(() => {
    setAudio(props.track);
    setCurrentTime(0); // Reset the current time
    setSeekValue(0); // Reset the seek value
    setIsSeeking(false);
    props.setGIsPlaying(false);
    props.setChkOrgin("Player");

    console.log("Refss");
    console.log(audioRef);
    console.log(prevAudioRef);
    console.log(prevTrack);
    console.log(shouldPlay);
  }, [props.track]);

  useEffect(() => {
    const newAudio = new Audio(props.track);

    const updateTime = () => {
      if (!isSeeking) {
        setCurrentTime(newAudio.currentTime);
        setSeekValue((newAudio.currentTime / newAudio.duration) * 100);
      }
    };

    newAudio.addEventListener("timeupdate", updateTime);

    const loadedMetadata = () => {
      setDuration(newAudio.duration);
      setIsDurationLoaded(true);
    };

    newAudio.addEventListener("loadedmetadata", loadedMetadata);

    const handleEnded = () => {
      setCurrentTime(0);
      setSeekValue(0);
      props.setGIsPlaying(false);
      props.setChkOrgin("Player");
    };

    newAudio.addEventListener("ended", handleEnded);

    if (shouldPlay.current) {
      newAudio.play();
      shouldPlay.current = false;
    }

    if (prevAudioRef.current) {
      prevAudioRef.current.pause();
    }

    audioRef.current = newAudio;
    prevAudioRef.current = newAudio;
    prevTrack.current = props.track;

    return () => {
      newAudio.pause();
      newAudio.removeEventListener("timeupdate", updateTime);
      newAudio.removeEventListener("loadedmetadata", loadedMetadata);
      newAudio.removeEventListener("ended", handleEnded);
    };
  }, [audioSource, isSeeking]);

  const handlePlayButtonClick = () => {
    props.setChkOrgin("Player");

    if (props.track !== prevTrack.current) {
      shouldPlay.current = true;
    } else {
      if (audioRef.current.paused) {
        audioRef.current.play();
        props.setGIsPlaying(true);
      } else {
        audioRef.current.pause();
        props.setGIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    props.setChkOrgin("Player");
    if (audioRef.current !== null && audioSource !== "no-track" && audioSource !== "") {
      if (audioSource !== prevTrack.current) {
        shouldPlay.current = true;
      } else {
        if (audioRef.current.paused) {
          audioRef.current.play();
          props.setGIsPlaying(true);
        }
      }
    }
  }, [audioRef.current]);

  useEffect(() => {
    if (props.track !== "" && props.chkorigin == "Main") {
      if (props.track !== prevTrack.current) {
        shouldPlay.current = true;
      } else {
        if (audioRef.current.paused) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
      }
    }
  }, [props.ogIsplaying]);

  const handleSliderChange = (value) => {
    const newTime = (duration * value) / 100;
    audioRef.current.pause();
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    setSeekValue(value);

    if (!audioRef.current.paused) {
      audioRef.current.play();
      props.setGIsPlaying(true);
    }
  };
  useEffect(() => {
    props.setGSeekValue(seekValue);
  }, [seekValue]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <Toaster />

      <div id="wrapper">
        <div id="screen">
          <div id="content-wrap">
            <div id="background"></div>
            <div
              id="content"
              style={{
                height: "90vh",
              }}
              className="d-flex align-items-center bg-none"
            >
              <div className="w-100">
                <div className="w-100 text-center" style={{ position: "absolute", top: "5%" }}>
                  <div className=" text-light mx-auto fw-bold" style={{ width: "20%" }}>
                    <h3 className="text-center display-2 mb-0" style={{ color: "#e74c3c" }}>
                      {userRating ? userRating : "NA"}
                    </h3>
                    <hr className="my-0" />
                    <h3 className="text-center display-2">{avgRating ? avgRating : "NA"}</h3>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center fs-3 text-light w-100  mb-3">
                  <i onClick={() => setCommentPrompt(true)} className="fa fa-comments-o"></i>
                  <i className="fa fa-paper-plane"></i>
                  <i onClick={() => setSharePropmt(true)} className="fa fa-share"></i>

                  <i onClick={() => setToggle(true)} className="fa fa-bar-chart"></i>
                </div>
                <div class="mb-3 pb-2">
                  <h3 className="text-light mb-0 pb-0">{props.GTrackname}</h3>
                  <h6 className="text-light">{props.GArtistname} </h6>
                </div>
                {/* Player Controls Start===> */}
                {audioSource !== "no-track" && (
                  <>
                    <audio ref={audioRef} id="audioPlayer">
                      <source src={audioSource} type="audio/mpeg" />
                    </audio>
                    <div id="timeline">
                      <span id="current-time">{formatTime(currentTime)}</span>
                      <span id="total-time">{isDurationLoaded ? formatTime(duration) : "--:--"}</span>
                      <Slider value={seekValue} onChange={handleSliderChange} propsG={props} />
                    </div>
                    <div id="controls">
                      <span id="previous-btn">
                        <i className="fa fa-step-backward fa-fw" aria-hidden="true"></i>
                      </span>
                      {audioRef.current !== null && (
                        <span id="play-btn" onClick={handlePlayButtonClick}>
                          {audioRef.current.paused ? (
                            <>
                              <i className="fa fa-play-circle fa-fw display-1" aria-hidden="true"></i>
                            </>
                          ) : (
                            <>
                              <i className="fa fa-pause-circle fa-fw display-1" aria-hidden="true"></i>
                            </>
                          )}
                        </span>
                      )}
                      <span id="next-btn">
                        <i className="fa fa-step-forward fa-fw" aria-hidden="true"></i>
                      </span>
                    </div>
                  </>
                )}
                {/* Player Controlls End===> */}

                <div className="w-100 text-center mt-5">
                  <a href={props.external} target="_blank" className="btn btn-light btn rounded-pill text-dark mx-auto " style={{ mixBlendMode: "screen" }}>
                    <p className="align-middle py-0 d-inline ms-1">Play on Spotify</p>
                    <i className="fa fa-spotify ms-1 fs-1 align-middle"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {stoggle && (
        <div className="position-absolute bg-black pt-5 px-2" style={{ height: "100%", width: "100%", zIndex: 99, top: 0, left: 0 }} data-aos="fade-up">
          <button type="button" onClick={() => setToggle(false)} className="btn btn-lg bg-none text-light ps-0 position-absolute top-0 start-0 ms-2 mt-2">
            <i className="fa fa-chevron-down"></i>
          </button>
          <div className="container text-center">
            <Slidercirlce recrate={(e) => setRecallRating(e)} ogrec={recallRating} trackid={props.tid} artistId={props.aid} albumId={props.alid} urating={userRating ? userRating : ""} />
            <div className="w-100 text-light mt-2 px-3">
              <div className="toglers d-flex my-4 mt-2 py-3 ">
                <button className={`togglers text-light ${commRbtn == "a" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("a")}>
                  {allComments !== undefined ? allComments.length : "0"} Comment
                </button>
                <button className={`togglers text-light ${commRbtn == "b" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("b")}>
                  Following {followingComment ? followingComment.length : "0"}
                </button>
                <button className={`togglers text-light ${commRbtn == "c" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("c")}>
                  Followers 0
                </button>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <h1 className="display-6 ">All Critiqs</h1>
                <i className="fa fa-bar-chart display-6 text-center"></i>
              </div>

              <div
                className="hideYscroll"
                style={{
                  height: "52vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingBottom: "200px",
                }}
              >
                {console.log("All Comments /***///*")}
                {console.log(allComments)}
                {commRbtn == "a" && (
                  <>
                    {allComments !== undefined &&
                      allComments.map((ini) => {
                        return <Comments username={ini.user__username} ratings={ini.rating} comment={ini.comment} origin="rater" />;
                      })}
                  </>
                )}

                {commRbtn == "b" && (
                  <>
                    {followingComment !== undefined &&
                      followingComment.map((ini) => {
                        return <Comments username={ini.user} ratings={ini.rating} comment={ini.comment} origin="rater" />;
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {sharePrompt && (
        <div className="position-absolute bg-dark pt-5 px-4 rounded-top" style={{ height: "60vh", width: "101%", zIndex: 99, bottom: 0, left: 0 }} data-aos="fade-up">
          <div className="d-flex justify-content-between ">
            <div className="text-center">
              <i className="fa fa-link mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Share Link</p>
            </div>
            <div className="text-center">
              <i className="fa fa-whatsapp mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Whatsapp</p>
            </div>

            <div className="text-center">
              <i className="fa fa-instagram mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Instagram</p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <div className="text-center">
              <i className="fa fa-facebook-square mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Facebook</p>
            </div>
            <div className="text-center">
              <i className="fa fa-snapchat mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Snapchat</p>
            </div>
            <div className="text-center">
              <i className="fa fa-twitter mb-2 fw-bold text-dark bg-light p-4 rounded-circle" style={{ fontSize: "45px" }}></i>
              <p className="fs-5 text-light">Twitter</p>
            </div>
          </div>

          <button type="button" onClick={() => setSharePropmt(false)} className="btn btn-lg text-danger bg-none w-100 text-center fs-1 ps-0 position-absolute bottom-0 start-0 ms-2 mt-2">
            Close
          </button>
        </div>
      )}

      {commentPrompt && (
        <div
          className="position-absolute pt-4 px-2 "
          style={{
            borderTopRightRadius: "35px",
            borderTopLeftRadius: "35px",
            height: "90vh",
            width: "101%",
            zIndex: 99,
            bottom: 0,
            left: 0,
            backgroundColor: "#1C1C1C",
          }}
          data-aos="fade-up"
        >
          <i
            onClick={() => setCommentPrompt(false)}
            style={{ padding: "10px 12px " }}
            className="fa fa-times fs-5 bg-light rounded-circle text-dark fw-lighter position-absolute top-1 end-0 me-3 mt-0"
          ></i>
          <h3 className="display-5 text-light text-center">Reactions</h3>

          <div className="toglers d-flex my-4 mt-2 py-3 ">
            <button className={`togglers text-light ${commRbtn == "a" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("a")}>
              {allComments !== undefined ? allComments.length : "0"} Comment
            </button>
            <button className={`togglers text-light ${commRbtn == "b" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("b")}>
              Following {followingComment ? followingComment.length : "0"}
            </button>
            <button className={`togglers text-light ${commRbtn == "c" ? "togglersActive" : ""}`} onClick={() => setcommRbtn("c")}>
              Followers 0
            </button>
          </div>
          <div className="container text-center">
            <div className="w-100 text-light mt-2">
              <div
                className="hideYscroll"
                style={{
                  height: "70vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingBottom: "200px",
                }}
              >
                {console.log("All Comments /***///*")}
                {console.log(allComments)}
                {commRbtn == "a" && (
                  <>
                    {allComments !== undefined &&
                      allComments.map((ini) => {
                        return <Comments username={ini.user__username} ratings={ini.rating} comment={ini.comment} origin="comments" />;
                      })}
                  </>
                )}
                {console.log("Following Comments /***///*")}
                {console.log(followingComment)}

                {commRbtn == "b" && (
                  <>
                    {followingComment !== undefined &&
                      followingComment.map((ini) => {
                        return <Comments username={ini.user} ratings={ini.rating} comment={ini.comment} origin="comments" />;
                      })}
                  </>
                )}
              </div>
              <div
                style={{
                  zIndex: "99",
                  bottom: "0px",
                  left: "0",
                  width: "100%",
                  backgroundColor: "#1C1C1C",
                }}
                className="  position-absolute border-top border-dark py-3 px-3"
              >
                <form class="d-flex w-100" onSubmit={handleCommentSubmit}>
                  <input
                    style={{ background: "#B1B1B1" }}
                    class="form-control rounded-3  text-dark"
                    type="text"
                    placeholder="Add Comment"
                    aria-label="comment"
                    onChange={(e) => setUcomment({ ...uComment, comment: e.target.value, track_id: props.tid })}
                    value={uComment.comment}
                    disabled={disab}
                  />
                  <button style={{ background: "#B1B1B1", height: "50px", aspectRatio: 1 }} className="btn rounded-circle text-dark ms-4 fs-4 me-2" type="submit" disabled={disab}>
                    {disab ? <span class="spinner-border spinner-border-sm "></span> : <i className="fa fa-paper-plane " />}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Player;
